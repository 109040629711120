import { AxiosResponse } from 'axios';
import { api } from '../../providers/base-api';
import {
  GetFilterCompaniesListQuery,
  GetFilterCompaniesListResponse,
  GetFilterSegmentationTagsListResponse,
  GetFilterServicesListResponse,
  GetFilterTeamsListQuery,
  GetFilterTeamsListResponse,
  GetFilterUsersListQuery,
  GetFilterUsersListResponse,
  GetFilterUsersWithEmailListResponse,
} from '../../utils/types/data/services/filters';

export async function getFilterUsersListRequest(
  query: GetFilterUsersListQuery,
): Promise<GetFilterUsersListResponse[]> {
  const response = await api.get<GetFilterUsersListResponse[]>(
    'filters/users',
    {
      params: query,
    },
  );

  return response.data;
}

export async function getFilterUsersListRequestV2(
  query: GetFilterUsersListQuery,
): Promise<AxiosResponse<GetFilterUsersListResponse[]>> {
  const response = await api.get<GetFilterUsersListResponse[]>(
    'filters/users',
    {
      params: query,
    },
  );

  return response;
}

export async function getFilterUsersWithEmailListRequest(
  query: GetFilterUsersListQuery,
): Promise<AxiosResponse<GetFilterUsersWithEmailListResponse[]>> {
  const response = await api.get<GetFilterUsersWithEmailListResponse[]>(
    'filters/users-mail',
    {
      params: query,
    },
  );

  return response;
}

export async function getFilterTeamsListRequest(
  query: GetFilterTeamsListQuery,
): Promise<AxiosResponse<GetFilterTeamsListResponse[]>> {
  const response = await api.get<GetFilterTeamsListResponse[]>(
    'filters/teams',
    {
      params: query,
    },
  );

  return response;
}

export async function getFilterCompaniesListRequest(
  query?: GetFilterCompaniesListQuery,
): Promise<GetFilterCompaniesListResponse[]> {
  const response = await api.get<GetFilterCompaniesListResponse[]>(
    'filters/companies',
    {
      params: query,
    },
  );

  return response.data;
}

export async function getFilterServicesListRequest(): Promise<
  GetFilterServicesListResponse[]
> {
  const response = await api.get<GetFilterServicesListResponse[]>(
    'filters/services',
  );

  return response.data;
}

export async function getFilterCompaniesListRequestV2(
  query?: GetFilterCompaniesListQuery,
): Promise<AxiosResponse<GetFilterCompaniesListResponse[]>> {
  const response = await api.get<GetFilterCompaniesListResponse[]>(
    'filters/companies',
    {
      params: query,
    },
  );

  return response;
}

export async function getFilterSegmentationTagsListRequest(): Promise<
  AxiosResponse<GetFilterSegmentationTagsListResponse[]>
> {
  const response = await api.get<GetFilterSegmentationTagsListResponse[]>(
    'filters/segmentation-tags',
  );

  return response;
}
