import { useFetch } from '../../../hooks/use-fetch';
import { errorNotification } from '../../../providers/mantine-notifications';
import {
  UseGetUsersFilterProps,
  UseGetCompaniesFilterProps,
  UseGetTeamsFilterProps,
} from '../../../utils/types/data/hooks/filters';
import {
  GetFilterSegmentationTagsListResponse,
  GetFilterTeamsListResponse,
  GetFilterUsersListResponse,
  GetFilterUsersWithEmailListResponse,
} from '../../../utils/types/data/services/filters';
import {
  getFilterCompaniesListRequestV2,
  getFilterSegmentationTagsListRequest,
  getFilterTeamsListRequest,
  getFilterUsersListRequestV2,
  getFilterUsersWithEmailListRequest,
} from '../../services/filters';

export function useGetUsersFilter() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<GetFilterUsersListResponse[]>();

  const fetch = ({ query }: UseGetUsersFilterProps) =>
    fetchData({
      fetcher: () => getFilterUsersListRequestV2(query),
      onError: () => {
        errorNotification({
          title: 'Erro ao buscar usuários.',
          message: 'tente novamente.',
        });
      },
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useGetUsersWithEmailFilter() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<GetFilterUsersWithEmailListResponse[]>();

  const fetch = ({ query }: UseGetUsersFilterProps) =>
    fetchData({
      fetcher: () => getFilterUsersWithEmailListRequest(query),
      onError: () => {
        errorNotification({
          title: 'Erro ao buscar usuários.',
          message: 'tente novamente.',
        });
      },
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useGetCompaniesFilter() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<GetFilterUsersListResponse[]>();

  const fetch = ({ query }: UseGetCompaniesFilterProps) =>
    fetchData({
      fetcher: () => getFilterCompaniesListRequestV2(query),
      onError: () => {
        errorNotification({
          title: 'Erro ao buscar clientes.',
          message: 'tente novamente.',
        });
      },
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useGetSegmentationTagsFilter() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<GetFilterSegmentationTagsListResponse[]>();

  const fetch = () =>
    fetchData({
      fetcher: () => getFilterSegmentationTagsListRequest(),
      onError: () => {
        errorNotification({
          title: 'Erro ao buscar tags.',
          message: 'tente novamente.',
        });
      },
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useGetTeamsFilter() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<GetFilterTeamsListResponse[]>();

  const fetch = ({ query }: UseGetTeamsFilterProps) =>
    fetchData({
      fetcher: () => getFilterTeamsListRequest(query),
      onError: () => {
        errorNotification({
          title: 'Erro ao buscar teams.',
          message: 'tente novamente.',
        });
      },
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}
