import {
  Button,
  ColorInput,
  ColorSwatch,
  Flex,
  Grid,
  Group,
  Menu,
  Modal,
  Text,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconCirclePlus, IconDotsVertical, IconEdit } from '@tabler/icons';
import { DataTable } from 'mantine-datatable';
import { useEffect, useState } from 'react';
import { CustomLoader } from '../../components/CustomLoader';
import { Page } from '../../components/Page';
import {
  useCreateSegmentationTag,
  useListSegmentationTag,
  useUpdateSegmentationTag,
} from '../../data/hooks/segmentation-tags';
import { ListSegmentationTagsResponse } from '../../utils/types/data/services/segmentation-tags';

type PageModalState = 'add-tag' | 'edit-tag' | null;

export function SegmentationTagList() {
  const [pageModalVisible, setPageModalVisible] =
    useState<PageModalState>(null);
  const formCreateTag = useForm({
    initialValues: {
      name: '',
      color: '',
      background: '',
    },
  });
  const formEditTag = useForm({
    initialValues: {
      name: '',
      color: '',
      background: '',
      id: '',
    },
  });
  const {
    fetch: listSegmentationTagFetcher,
    response: listSegmentationTagData,
    loading: listSegmentationTagLoader,
  } = useListSegmentationTag();
  const {
    fetch: createSegmentationTagFetcher,
    loading: createSegmentationTagLoader,
  } = useCreateSegmentationTag();
  const {
    fetch: updateSegmentationTagFetcher,
    loading: updateSegmentationTagLoader,
  } = useUpdateSegmentationTag();

  function setAddTag() {
    setPageModalVisible('add-tag');
  }

  function setEditTag(tag: ListSegmentationTagsResponse) {
    formEditTag.setValues({
      background: tag.background,
      color: tag.color,
      name: tag.name,
      id: String(tag.id),
    });

    setPageModalVisible('edit-tag');
  }

  function handleCloseModal() {
    formCreateTag.reset();
    formEditTag.reset();
    setPageModalVisible(null);
  }

  async function getTags() {
    listSegmentationTagFetcher();
  }

  async function handleCreateTag(values: typeof formCreateTag.values) {
    await createSegmentationTagFetcher({
      data: {
        background: values.background,
        color: values.color,
        name: values.name,
      },
      onSuccess: () => {
        formCreateTag.reset();
        setPageModalVisible(null);
        getTags();
      },
    });
  }

  async function handleUpdateTag(values: typeof formEditTag.values) {
    await updateSegmentationTagFetcher({
      id: Number(values.id),
      data: {
        background: values.background,
        color: values.color,
        name: values.name,
      },
      onSuccess: () => {
        formEditTag.reset();
        setPageModalVisible(null);
        getTags();
      },
    });
  }

  useEffect(() => {
    getTags();
  }, []);

  return (
    <Page title="Tags de Segmentação">
      <CustomLoader
        loading={
          listSegmentationTagLoader ||
          createSegmentationTagLoader ||
          updateSegmentationTagLoader
        }
      />
      <Flex direction="column" w="99%">
        <Flex align="center" justify="end" mb={8}>
          <Button
            color="dark"
            leftIcon={<IconCirclePlus />}
            onClick={setAddTag}
          >
            Novo
          </Button>
        </Flex>
        <DataTable
          fetching={listSegmentationTagLoader}
          height={500}
          noRecordsText="Sem tags"
          withBorder
          borderRadius="sm"
          striped
          highlightOnHover
          records={listSegmentationTagData}
          columns={[
            {
              title: 'Texto',
              accessor: 'name',
            },
            {
              title: 'Cor do texto',
              accessor: 'color',
              width: 150,
              render: ({ color }) => (
                <ColorSwatch
                  component="button"
                  color={color}
                  radius={2}
                  sx={{ color: '#fff', cursor: 'pointer' }}
                />
              ),
            },
            {
              title: 'Cor de fundo',
              accessor: 'background',
              width: 150,
              render: ({ background }) => (
                <ColorSwatch
                  component="button"
                  color={background}
                  radius={2}
                  sx={{ color: '#fff', cursor: 'pointer' }}
                />
              ),
            },
            {
              accessor: '',
              title: '',
              width: 150,
              render: (tagItem) => (
                <Menu>
                  <Menu.Target>
                    <Button color="blue" variant="subtle" w={40} p={0}>
                      <IconDotsVertical />
                    </Button>
                  </Menu.Target>
                  <Menu.Dropdown style={{ position: 'absolute' }}>
                    <Menu.Item
                      onClick={() => setEditTag(tagItem)}
                      icon={<IconEdit size={14} />}
                    >
                      Editar
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>
              ),
            },
          ]}
        />
      </Flex>
      <Modal
        size={400}
        opened={pageModalVisible === 'add-tag'}
        onClose={() => handleCloseModal()}
        title={<Text fw={'bold'}>Criar nova tag </Text>}
      >
        <form onSubmit={formCreateTag.onSubmit(handleCreateTag)}>
          <Grid>
            <Grid.Col>
              <TextInput
                required
                withAsterisk
                label="Texto"
                placeholder="texto da tag"
                type="text"
                {...formCreateTag.getInputProps('name')}
              />
            </Grid.Col>
            <Grid.Col>
              <ColorInput
                label="Cor do texto"
                placeholder="escolha uma cor"
                required
                withAsterisk
                {...formCreateTag.getInputProps('color')}
              />
            </Grid.Col>
            <Grid.Col>
              <ColorInput
                label="Cor de fundo"
                placeholder="escolha uma cor"
                required
                withAsterisk
                {...formCreateTag.getInputProps('background')}
              />
            </Grid.Col>
          </Grid>
          <Group position="right" mt={16}>
            <Button color="blue" type="submit">
              Salvar
            </Button>
          </Group>
        </form>
      </Modal>
      <Modal
        size={400}
        opened={pageModalVisible === 'edit-tag'}
        onClose={() => handleCloseModal()}
        title={<Text fw={'bold'}>Editar tag </Text>}
      >
        <form onSubmit={formEditTag.onSubmit(handleUpdateTag)}>
          <Grid>
            <Grid.Col>
              <TextInput
                required
                withAsterisk
                label="Texto"
                placeholder="texto da tag"
                type="text"
                {...formEditTag.getInputProps('name')}
              />
            </Grid.Col>
            <Grid.Col>
              <ColorInput
                label="Cor do texto"
                placeholder="escolha uma cor"
                required
                withAsterisk
                {...formEditTag.getInputProps('color')}
              />
            </Grid.Col>
            <Grid.Col>
              <ColorInput
                label="Cor de fundo"
                placeholder="escolha uma cor"
                required
                withAsterisk
                {...formEditTag.getInputProps('background')}
              />
            </Grid.Col>
          </Grid>
          <Group position="right" mt={16}>
            <Button color="blue" type="submit">
              Salvar
            </Button>
          </Group>
        </form>
      </Modal>
    </Page>
  );
}
