import { ApprovalType, DefectType } from '../utils/enums';
import { Company } from './company';
import { OrderFile } from './order-file';
import { OrderLayout } from './order-layout';
import { Service } from './service';
import { Team } from './team';

export enum OrderStatus {
  CREATED = 'created',
  WAITING_MATERIAL = 'waiting_material',
  MATERIAL_RECEIVED = 'material_received',
  RELEASED_PRODUCTION = 'released_production',
  ON_PRODUCTION = 'on_production',
  PRODUCED = 'produced',
  WAITING_PACKAGING = 'waiting_packaging',
  FINISHED = 'finished',
  DISPATCHED = 'dispatched',
  CANCELLED = 'cancelled',
  CHANGE_MATERIAL = 'waiting_material_change',
  LOWER_QTDE = 'lower_quantity',
  PAUSED = 'paused',
}

export enum FinancialStatus {
  PENDING = 'pending',
  INVOICED = 'invoiced',
  INITIAL = 'initial',
}

export enum OrderPaymentStatus {
  PENDING = 'pending',
  PAID = 'paid',
  NOT_PAID = 'not_paid',
  REVIEWED = 'reviewed',
}

export enum OrderProblem {
  INCORRECT_QUANTITY = 'incorrect_quantity',
  PRODUCT_DEFECT = 'product_defect',
}

export enum OrderFirstPieceStatus {
  PENDING = 'pending',
  APPROVED = 'approved',
  REPROVED = 'reproved',
}

export type Order = {
  id: number;
  service: Service;
  company: Company;
  team: Team;
  os: string;
  internalNumber: string;
  status: OrderStatus;
  product: string;
  productMaterial: string;
  productColor: string;
  engravingText: string;
  quantity: number;
  quantityVolume?: number;
  note?: string;
  fileLink?: string;
  price?: string;
  samplePrice?: string;
  clichePrice?: string;
  pholitoPrice?: string;
  manipulationPrice?: string;
  vectorizationPrice?: string;
  hasDefect: DefectType;
  defectAmount?: number;
  carrierName?: string;
  needsPickUp?: boolean;
  needsPostal?: boolean;
  pickUpNumber?: string;
  pickUpLocation?: string;
  pickUpName?: string;
  createdAt: string;
  updatedAt: string;
  pickUpedAt?: string;
  pickUpForecast?: string;
  finishedAt?: string;
  finishForecast?: string;
  finishVendorForecast?: string;
  receiptedAt?: string;
  receiptForecast?: string;
  dispatchedAt?: string;
  dispatchForecast?: string;
  dispatchLocation?: string;
  deletedAt?: string;
  issueDate?: string;
  files: OrderFile[];
  layout: OrderLayout | null;
  financialStatus?: FinancialStatus;
  financialNote?: string;
  paymentType?: string;
  job?: string;
  supplier?: string;
  productCodeSupplier?: string;
  rework?: boolean;
  reworkUser?: string;
  reworkOriginOrder?: Order;
  isSample?: boolean;
  dispatchName: string;
  approvalBy?: string;
  approval?: boolean;
  approvalType?: ApprovalType;
  approvalOn?: string;
  changeColorPrice?: string;
  changeColorTotalPrice?: string;
  origin: Order;
  requestAssociation: boolean;
};
