import { useFetch } from '../../../hooks/use-fetch';
import { OrderMaterial } from '../../../models/order-material';
import { OrderMaterialVolume } from '../../../models/order-material-volume';
import { errorNotification } from '../../../providers/mantine-notifications';
import { translateServerHttpErrors } from '../../../utils/helpers';
import { PaginatedResponse } from '../../../utils/types';
import {
  UseCreateOrderMaterialVolume,
  UseDeleteOrderMaterialFileProps,
  UseDeleteOrderMaterialProps,
  UseFinishOrderMaterialVolumeTransfer,
  UseGetOrderMaterialFilesProps,
  UseGetOrderMaterialsByOrderProps,
  UseGetOrderMaterialsVolumesProps,
  UseGetPaginatedOrderMaterialProps,
  UseListOrderMaterialAssociatedsProps,
  UseOrderMaterialAssociateProps,
  UseRequestMaterialTransfer,
  UseShowOrderMaterialsProps,
  UseTransferOrderMaterialVolume,
} from '../../../utils/types/data/hooks/order-materials';
import { ListOrderMaterialAssociatedsResponse } from '../../../utils/types/data/services/order-material';
import { OrderMaterialFileResponse } from '../../../utils/types/data/services/order-material-files';
import {
  createOrderMaterialVolume,
  deleteOrderMaterialFileRequest,
  deleteOrderMaterialRequest,
  finishOrderMaterialVolumeTransfer,
  getOrderMaterialByOrder,
  getOrderMaterialFilesRequest,
  getOrderMaterialVolumes,
  listOrderMaterialAssociatedsRequest,
  orderMaterialAssociateRequest,
  paginatedOrderMaterialsRequest,
  requestMaterialTransfer,
  showOrderMaterialsRequest,
  transferOrderMaterialVolume,
} from '../../services/order-material';

export function useDeleteOrderMaterial() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<void>();

  const fetch = ({ id, onSuccess }: UseDeleteOrderMaterialProps) =>
    fetchData({
      fetcher: () => deleteOrderMaterialRequest(id),
      onError: () => {
        errorNotification({
          title: 'Erro ao excluir AR.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useGetOrderMaterialFiles() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<OrderMaterialFileResponse[]>();

  const fetch = ({ id, onSuccess }: UseGetOrderMaterialFilesProps) =>
    fetchData({
      fetcher: () => getOrderMaterialFilesRequest(id),
      onError: () => {
        errorNotification({
          title: 'Erro ao buscar arquivos.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useDeleteOrderMaterialFile() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<void>();

  const fetch = ({ fileId, onSuccess }: UseDeleteOrderMaterialFileProps) =>
    fetchData({
      fetcher: () => deleteOrderMaterialFileRequest(fileId),
      onError: () => {
        errorNotification({
          title: 'Erro ao remover arquivo.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useShowOrderMaterials() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<OrderMaterial>();

  const fetch = ({ id, onSuccess }: UseShowOrderMaterialsProps) =>
    fetchData({
      fetcher: () => showOrderMaterialsRequest(id),
      onError: (error: any) => {
        errorNotification({
          title: 'Erro ao buscar AR.',
          message: translateServerHttpErrors(error, 'tente novamente.'),
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useGetPaginatedOrderMaterial() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<PaginatedResponse<OrderMaterial>>();

  const fetch = ({ query, onSuccess }: UseGetPaginatedOrderMaterialProps) =>
    fetchData({
      fetcher: () => paginatedOrderMaterialsRequest(query),
      onError: (error: any) => {
        errorNotification({
          title: 'Erro ao listar ARs.',
          message: translateServerHttpErrors(error, 'tente novamente.'),
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useGetOrderMaterialsVolumes() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<OrderMaterialVolume[]>();

  const fetch = ({ id, onSuccess }: UseGetOrderMaterialsVolumesProps) =>
    fetchData({
      fetcher: () => getOrderMaterialVolumes(id),
      onError: (error: any) => {
        errorNotification({
          title: 'Erro ao buscar volumes da AR.',
          message: translateServerHttpErrors(error, 'tente novamente.'),
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useGetOrderMaterialsByOrder() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<OrderMaterial[]>();

  const fetch = ({ orderId, onSuccess }: UseGetOrderMaterialsByOrderProps) =>
    fetchData({
      fetcher: () => getOrderMaterialByOrder(orderId),
      onError: (error: any) => {
        errorNotification({
          title: 'Erro ao buscar ARs.',
          message: translateServerHttpErrors(error, 'tente novamente.'),
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useCreateOrderMaterialVolume() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<void>();

  const fetch = ({ id, data, onSuccess }: UseCreateOrderMaterialVolume) =>
    fetchData({
      fetcher: () => createOrderMaterialVolume(id, data),
      onError: (error: any) => {
        errorNotification({
          title: 'Erro ao criar volume para AR.',
          message: translateServerHttpErrors(error, 'tente novamente.'),
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useTransferOrderMaterialVolume() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<void>();

  const fetch = ({ id, data, onSuccess }: UseTransferOrderMaterialVolume) =>
    fetchData({
      fetcher: () => transferOrderMaterialVolume(id, data),
      onError: (error: any) => {
        errorNotification({
          title: 'Erro ao transferir volume.',
          message: translateServerHttpErrors(error, 'tente novamente.'),
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useFinishOrderMaterialVolumeTransfer() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<void>();

  const fetch = ({ id, onSuccess }: UseFinishOrderMaterialVolumeTransfer) =>
    fetchData({
      fetcher: () => finishOrderMaterialVolumeTransfer(id),
      onError: (error: any) => {
        errorNotification({
          title: 'Erro ao finalizar transferência de volume.',
          message: translateServerHttpErrors(error, 'tente novamente.'),
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useRequestMaterialTransfer() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<void>();

  const fetch = ({ id, onSuccess }: UseRequestMaterialTransfer) =>
    fetchData({
      fetcher: () => requestMaterialTransfer(id),
      onError: (error: any) => {
        errorNotification({
          title: 'Erro ao solicitar transferência de volume.',
          message: translateServerHttpErrors(error, 'tente novamente.'),
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useOrderMaterialAssociate() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<void>();

  const fetch = ({ id, orderId, onSuccess }: UseOrderMaterialAssociateProps) =>
    fetchData({
      fetcher: () => orderMaterialAssociateRequest(id, orderId),
      onError: (error: any) => {
        errorNotification({
          title: 'Erro ao associar material.',
          message: translateServerHttpErrors(error, 'tente novamente.'),
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useListOrderMaterialAssociateds() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<ListOrderMaterialAssociatedsResponse[]>();

  const fetch = ({
    orderId,
    onSuccess,
  }: UseListOrderMaterialAssociatedsProps) =>
    fetchData({
      fetcher: () => listOrderMaterialAssociatedsRequest(orderId),
      onError: (error: any) => {
        errorNotification({
          title: 'Erro ao bustar materiais associados.',
          message: translateServerHttpErrors(error, 'tente novamente.'),
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}
