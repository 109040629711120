import { useFetch } from '../../../hooks/use-fetch';
import { errorNotification } from '../../../providers/mantine-notifications';
import { translateServerHttpErrors } from '../../../utils/helpers';
import {
  UseRemoveReceiptProps,
  UseReprintReceiptProps,
} from '../../../utils/types/data/hooks/receipts';
import {
  removeReceiptRequest,
  reprintReceiptRequest,
} from '../../services/receipt';

export function useRemoveReceipt() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<void>();

  const fetch = ({ params, onSuccess }: UseRemoveReceiptProps) =>
    fetchData({
      fetcher: () => removeReceiptRequest(params),
      onError: (error: any) => {
        errorNotification({
          title: 'Erro ao cancelar recibo.',
          message: translateServerHttpErrors(error, 'tente novamente.'),
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useReprintReceipt() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<Blob>();

  const fetch = ({ id, onSuccess }: UseReprintReceiptProps) =>
    fetchData({
      fetcher: () => reprintReceiptRequest(id),
      onError: (error: any) => {
        errorNotification({
          title: translateServerHttpErrors(error, 'Falha ao imprimir arquivo.'),
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}
