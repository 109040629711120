import { OrderProductionApprovalStatus } from '../../models/order-production-approval';

export const orderProductionApprovalStatusHumanized = {
  [OrderProductionApprovalStatus.APPROVED]: 'aprovado',
  [OrderProductionApprovalStatus.REPROVED]: 'reprovado',
  [OrderProductionApprovalStatus.PENDING]: 'pendente',
};

export const orderProductionApprovalStatusColor = {
  [OrderProductionApprovalStatus.APPROVED]: 'green',
  [OrderProductionApprovalStatus.REPROVED]: 'red',
  [OrderProductionApprovalStatus.PENDING]: 'orange',
};
