import { Card, Flex, Grid, Skeleton, Text } from '@mantine/core';
import { formatBRL, formatDateTime } from '../../utils/helpers';
import {
  GetByCloneOrderResponse,
  GetByReworkOrderResponse,
} from '../../utils/types/data/services/orders';

interface OrderParentProps {
  order: GetByCloneOrderResponse | GetByReworkOrderResponse;
  loading: boolean;
}

export function OrderParent({ order, loading }: OrderParentProps) {
  const price = parseFloat(order?.price || '0');
  const samplePrice = parseFloat(order?.samplePrice || '0');
  const photolitoPrice = parseFloat(order?.pholitoPrice || '0');
  const manipulationPrice = parseFloat(order?.manipulationPrice || '0');
  const vectorizationPrice = parseFloat(order?.vectorizationPrice || '0');
  const clichePrice = parseFloat(order?.clichePrice || '0');
  const quantity = Number(order?.quantity || '0');
  const unitPrice = Math.fround(price / quantity).toFixed(2);
  const orderUnitPrice = formatBRL(unitPrice);
  const changeColorTotalPrice = parseFloat(order?.changeColorTotalPrice || '0');
  const orderTotalPrice =
    price +
    samplePrice +
    photolitoPrice +
    manipulationPrice +
    vectorizationPrice +
    clichePrice +
    changeColorTotalPrice;

  return (
    <>
      {loading ? (
        <Skeleton height={120} radius="md" mt={16} mb={16} />
      ) : (
        <Card mb={16} shadow="sm">
          <Grid m={8} gutter="xs" columns={2}>
            <Grid.Col mt={8} mb={8} span={2}>
              <Text color="gray">Pedido Original</Text>
            </Grid.Col>
            <Grid.Col span={1}>
              <Flex direction="column">
                <Text fw="bold">OS</Text>
                <Text color="gray">{order.os}</Text>
              </Flex>
            </Grid.Col>
            <Grid.Col span={1}>
              <Flex direction="column">
                <Text fw="bold">Criado em</Text>
                <Text color="gray">
                  {order.createdAt ? formatDateTime(order.createdAt) : '--'}
                </Text>
              </Flex>
            </Grid.Col>
            <Grid.Col span={1}>
              <Flex direction="column">
                <Text fw="bold">Preço Clichê</Text>
                <Text color="gray">{formatBRL(order?.clichePrice || '0')}</Text>
              </Flex>
            </Grid.Col>
            <Grid.Col span={1}>
              <Flex direction="column">
                <Text fw="bold">Preço Fotolito</Text>
                <Text color="gray">
                  {formatBRL(order?.pholitoPrice || '0')}
                </Text>
              </Flex>
            </Grid.Col>
            <Grid.Col span={1}>
              <Flex direction="column">
                <Text fw="bold">Preço Manuseio</Text>
                <Text color="gray">
                  {formatBRL(order?.manipulationPrice || '0')}
                </Text>
              </Flex>
            </Grid.Col>
            <Grid.Col span={1}>
              <Flex direction="column">
                <Text fw="bold">Preço Vetorização</Text>
                <Text color="gray">
                  {formatBRL(order?.vectorizationPrice || '0')}
                </Text>
              </Flex>
            </Grid.Col>
            <Grid.Col span={1}>
              <Flex direction="column">
                <Text fw="bold">Preço Amostra</Text>
                <Text color="gray">{formatBRL(order?.samplePrice || '0')}</Text>
              </Flex>
            </Grid.Col>
            <Grid.Col span={1}>
              <Flex direction="column">
                <Text fw="bold">Preço (total gravação)</Text>
                <Text color="gray">{formatBRL(order?.price || '0')}</Text>
              </Flex>
            </Grid.Col>
            <Grid.Col span={1}>
              <Flex direction="column">
                <Text fw="bold">Preço Troca de cor (unitário)</Text>
                <Text color="gray">
                  {formatBRL(order?.changeColorPrice || '0')}
                </Text>
              </Flex>
            </Grid.Col>

            <Grid.Col span={1}>
              <Flex direction="column">
                <Text fw="bold">Preço (unitário)</Text>
                <Text color="gray">{orderUnitPrice}</Text>
              </Flex>
            </Grid.Col>
            <Grid.Col span={1}>
              <Flex direction="column">
                <Text fw="bold">Preço final</Text>
                <Text color="gray">
                  {formatBRL(orderTotalPrice.toFixed(2))}
                </Text>
              </Flex>
            </Grid.Col>
          </Grid>
        </Card>
      )}
    </>
  );
}
