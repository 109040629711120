import { CompanyCategory } from '../../models/company';

export const companyCategoryHumanized = {
  [CompanyCategory.AGENCY]: 'Agências de propaganda',
  [CompanyCategory.BRINDEIRO_G]: 'Brindeiros G',
  [CompanyCategory.BRINDEIRO_GG]: 'Brindeiros GG',
  [CompanyCategory.BRINDEIRO_M]: 'Brindeiros M',
  [CompanyCategory.BRINDEIRO_P]: 'Brindeiros P',
  [CompanyCategory.CLIENT]: 'Cliente final',
  [CompanyCategory.IMPORTER]: 'Importadora outras áreas',
  [CompanyCategory.IMPORTER_BRINDE]: 'Importadora ramo brinde',
  [CompanyCategory.INDUSTRY]: 'Indústria',
};

export const docTypeList = [
  { label: 'CNPJ', value: 'cnpj' },
  { label: 'CPF', value: 'cpf' },
];

export const paymentTermList = [
  { label: 'Quantidade de dias', value: 'day_amount' },
  { label: 'Dias especificos', value: 'days' },
];

export const paymentTypeList = [
  { label: 'À vista', value: 'deposit' },
  { label: 'Boleto Bancário', value: 'bank_slip' },
];

export const companyCategoryOptionList = [
  {
    label: 'Agências de propaganda',
    value: CompanyCategory.AGENCY,
  },
  {
    label: 'Brindeiros G',
    value: CompanyCategory.BRINDEIRO_G,
  },
  {
    label: 'Brindeiros GG',
    value: CompanyCategory.BRINDEIRO_GG,
  },
  {
    label: 'Brindeiros M',
    value: CompanyCategory.BRINDEIRO_M,
  },
  {
    label: 'Brindeiros P',
    value: CompanyCategory.BRINDEIRO_P,
  },
  {
    label: 'Cliente final',
    value: CompanyCategory.CLIENT,
  },
  {
    label: 'Importadora outras áreas',
    value: CompanyCategory.IMPORTER,
  },
  {
    label: 'Importadora ramo brinde',
    value: CompanyCategory.IMPORTER_BRINDE,
  },
  {
    label: 'Indústria',
    value: CompanyCategory.INDUSTRY,
  },
];

export const companyOriginList = [
  { label: 'Anuncio do Google', value: 'Anuncio do Google' },
  { label: 'Email Marketing', value: 'Email Marketing' },
  { label: 'Instagram', value: 'Instagram' },
  { label: 'Indicação', value: 'Indicação' },
  { label: 'Outro', value: 'Outro' },
];
