import { OrderStatus } from './order';

export enum TicketStatus {
  OPEN = 'open',
  WAITING_INFO = 'waiting_info',
  ON_PROGRESS = 'on_progress',
  FINISHED = 'finished',
}

export enum TicketContactType {
  EMAIL = 'email',
  PHONE = 'phone',
  WHATSAPP = 'whatsapp',
}

export type Ticket = {
  id: number;
  code: string;
  needClean: boolean;
  needBuy: boolean;
  buyQuantity?: number;
  assignedUser?: string;
  status: TicketStatus;
  obs?: string;
  createdBy: string;
  createdAt: string;
  updatedAt?: string;
  finishedAt?: string;
  cleanProcess?: string;
  buyDescription?: string;
  contactType?: TicketContactType;
  contactName?: string;
  contact?: string;
  companyName: string;
  orderId: number;
  orderQuantity: number;
  orderOs: string;
  orderInternalNumber: string;
  orderServiceName: string;
  orderProduct: string;
  orderProductColor: string;
  orderProductMaterial: string;
  orderStatus: OrderStatus;
  orderOrigin: number;
  orderIsSample: boolean;
  orderRework: boolean;
  orderReworkOrigin: number;
};
