import { useFetch } from '../../../hooks/use-fetch';
import {
  OrderProducitonApprovalPaginatedResponse,
  OrderProductionApproval,
} from '../../../models/order-production-approval';
import { OrderProductionApprovalFile } from '../../../models/order-production-file';

import { errorNotification } from '../../../providers/mantine-notifications';
import {
  UseGetLastOrderProductionApprovalProps,
  UseGetOrderProductionFilesProps,
  UseGetOrderProductionToApproveProps,
  UseOrderProductionApprovalProps,
  UseRequestOrderProductionApprovalProps,
} from '../../../utils/types/data/hooks/order-productions';
import {
  getLastorderProductionApproval,
  listOrderProductionToApprovalsRequest,
  orderProductionApprovalListFiles,
  orderProductionApprovalsRequest,
  requestOrderProductionApproval,
} from '../../services/order-production-approval';

export function useOrderProductionDoApproval() {
  const {
    fetchData,
    state: { data: reponseData, error, loading },
  } = useFetch<void>();

  const fetch = ({ data, id, onSuccess }: UseOrderProductionApprovalProps) =>
    fetchData({
      fetcher: () => orderProductionApprovalsRequest(id, data),
      onError: () => {
        errorNotification({
          title: 'Erro ao avaliar produção.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    reponseData,
    error,
    loading,
  };
}

export function useGetOrderProductionFirstPieceToApprove() {
  const {
    fetchData,
    state: { data: reponseData, error, loading },
  } = useFetch<OrderProducitonApprovalPaginatedResponse>();

  const fetch = ({ query }: UseGetOrderProductionToApproveProps) =>
    fetchData({
      fetcher: () => listOrderProductionToApprovalsRequest(query),
      onError: () => {
        errorNotification({
          title: 'Erro ao buscar primeiras peças.',
          message: 'tente novamente.',
        });
      },
    });

  return {
    fetch,
    reponseData,
    error,
    loading,
  };
}

export function useRequestOrderProductionApproval() {
  const {
    fetchData,
    state: { data: reponseData, error, loading },
  } = useFetch<void>();

  const fetch = ({
    id,
    data,
    onSuccess,
  }: UseRequestOrderProductionApprovalProps) =>
    fetchData({
      fetcher: () => requestOrderProductionApproval(id, data),
      onError: () => {
        errorNotification({
          title: 'Erro ao solicitar aprovação.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    reponseData,
    error,
    loading,
  };
}

export function useGetOrderProductionFiles() {
  const {
    fetchData,
    state: { data: reponseData, error, loading },
  } = useFetch<OrderProductionApprovalFile[]>();

  const fetch = ({
    orderProductionApprovalId,
    onSuccess,
  }: UseGetOrderProductionFilesProps) =>
    fetchData({
      fetcher: () =>
        orderProductionApprovalListFiles(orderProductionApprovalId),
      onError: () => {
        errorNotification({
          title: 'Erro ao buscar arquivos.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    reponseData,
    error,
    loading,
  };
}

export function useGetLastOrderProductionApproval() {
  const {
    fetchData,
    state: { data: reponseData, error, loading },
  } = useFetch<OrderProductionApproval>();

  const fetch = ({
    orderId,
    onSuccess,
  }: UseGetLastOrderProductionApprovalProps) =>
    fetchData({
      fetcher: () => getLastorderProductionApproval(orderId),
      onError: () => {
        errorNotification({
          title: 'Erro ao buscar última aprovação de produção.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    reponseData,
    error,
    loading,
  };
}
