import { Badge, Flex, Group, ThemeIcon, Tooltip } from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconRefreshDot } from '@tabler/icons';
import { DataTable } from 'mantine-datatable';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { CustomLoader } from '../../components/CustomLoader';
import { Page } from '../../components/Page';
import { useGetOrderPcp } from '../../data/hooks/orders';
import {
  getFilterCompaniesListRequest,
  getFilterServicesListRequest,
  getFilterTeamsListRequest,
} from '../../data/services/filters';
import { TeamConfigAlias } from '../../models/team-config';
import { formatLocale } from '../../providers/dayjs-plugins';
import { errorNotification } from '../../providers/mantine-notifications';
import { RootState } from '../../providers/store';
import { orderStatusColor, orderStatusHumanized } from '../../utils/constants';
import {
  GetFilterCompaniesListResponse,
  GetFilterServicesListResponse,
  GetFilterTeamsListResponse,
} from '../../utils/types/data/services/filters';
import PCPListPageFilter from './sub-components/page-filter';

export type FilterFormType = {
  os?: string;
  service?: string;
  company?: string;
  team?: string;
  product?: string;
  finishedAt?: string;
};

export function PCPList() {
  const [formCollapsed, setFormCollapsed] = useState(false);
  const currentPage = useRef(1);
  const currentPageLimit = useRef(10);
  const [companyList, setCompanyList] = useState<
    GetFilterCompaniesListResponse[]
  >([]);
  const [serviceList, setServiceList] = useState<
    GetFilterServicesListResponse[]
  >([]);
  const [teamList, setTeamList] = useState<GetFilterTeamsListResponse[]>([]);

  const {
    fetch: getOrderPcpFetcher,
    loading: getOrderPcpLoader,
    response: getOrderPcpResponse,
  } = useGetOrderPcp();

  const { user, userTeam } = useSelector((state: RootState) => state.auth);

  const teamConfilterFilter = useMemo(() => {
    return userTeam?.configs.find(
      (item) => item.alias === TeamConfigAlias.TEAM_FILTER,
    );
  }, []);

  const filterForm = useForm<FilterFormType>({
    initialValues: {
      os: '',
      service: '',
      company: '',
      team: '',
      product: '',
      finishedAt: undefined,
    },
  });

  function handleChangePageLimit(limit: number) {
    currentPageLimit.current = limit;
    getPcp();
  }

  function handlePaginate(page: number) {
    currentPage.current = page;
    getPcp();
  }

  function handleClear() {
    currentPage.current = 1;
    currentPageLimit.current = 10;
    filterForm.reset();
    getPcp();
  }

  async function getPcp() {
    const validatedFilter: any = {};

    Object.entries(filterForm.values).forEach(([key, val]) => {
      if (val !== '' && val !== null && val !== undefined) {
        validatedFilter[key] = val;
      }
    });

    await getOrderPcpFetcher({
      query: {
        page: currentPage.current,
        limit: currentPageLimit.current,
        ...validatedFilter,
      },
    });
  }

  async function getCompanies() {
    const response = await getFilterCompaniesListRequest({
      team:
        teamConfilterFilter?.value === 'false' ? undefined : Number(user?.team),
    });
    setCompanyList(response);
  }

  async function getServices() {
    try {
      const response = await getFilterServicesListRequest();
      setServiceList(response);
    } catch (error) {
      errorNotification({
        title: 'Erro ao buscar serviços.',
        message: 'tente novamente',
      });
    }
  }

  async function getTeams() {
    const response = await getFilterTeamsListRequest({});
    setTeamList(response.data);
  }

  useEffect(() => {
    getPcp();
    getServices();
    getCompanies();
    getTeams();
  }, []);

  return (
    <Page title="PCP">
      <CustomLoader loading={getOrderPcpLoader} />
      <Flex direction="column" w="99%">
        <Group mb={16} position="right">
          <PCPListPageFilter.Button
            isCollapsed={formCollapsed}
            onClick={() => setFormCollapsed(!formCollapsed)}
          />
        </Group>
        <PCPListPageFilter
          teamList={teamList}
          formFilter={filterForm}
          companyList={companyList}
          serviceList={serviceList}
          isCollapsed={formCollapsed}
          handleClear={() => handleClear()}
          handleSubmit={() => getPcp()}
        />
        <DataTable
          recordsPerPageLabel="Itens por página"
          recordsPerPageOptions={[10, 50, 100, 300]}
          onRecordsPerPageChange={(recordRange) =>
            handleChangePageLimit(recordRange)
          }
          height="75vh"
          noRecordsText="Sem pedidos"
          withBorder
          borderRadius="sm"
          striped
          highlightOnHover
          page={currentPage.current}
          totalRecords={getOrderPcpResponse?.meta.totalItems}
          recordsPerPage={getOrderPcpResponse?.meta.itemsPerPage ?? 10}
          onPageChange={handlePaginate}
          records={getOrderPcpResponse?.items}
          rowStyle={(record) =>
            record.rework ? { backgroundColor: 'rgba(255, 17, 0, 0.027)' } : {}
          }
          columns={[
            {
              accessor: 'rework',
              title: '',
              render: ({ rework }) =>
                rework ? (
                  <Tooltip color="grape" label="retrabalho">
                    <ThemeIcon color="grape" variant="light">
                      <IconRefreshDot size={24} />
                    </ThemeIcon>
                  </Tooltip>
                ) : (
                  ''
                ),
            },
            {
              accessor: 'os',
              title: 'OS',
            },
            {
              accessor: 'issueDate',
              title: 'Criado em',
              render: ({ createdAt }) =>
                createdAt && formatLocale(createdAt, 'DD/MM/YY HH:mm'),
            },
            {
              accessor: 'team_name',
              title: 'Equipe',
            },
            {
              accessor: 'company_name',
              title: 'Cliente',
            },
            {
              accessor: 'product',
              title: 'Produto',
              render: ({ product, productColor, productMaterial }) =>
                `${product}, ${productColor}, ${productMaterial}`,
            },
            {
              accessor: 'quantity',
              title: 'Qtde',
              width: 60,
            },
            {
              accessor: 'engravingText',
              title: 'Logotipo',
            },
            {
              accessor: 'service_name',
              title: 'Serviço',
            },
            {
              accessor: 'status',
              title: 'Status',
              render: ({ status }) => (
                <Badge color={orderStatusColor[status]} mb={6}>
                  {orderStatusHumanized[status]}
                </Badge>
              ),
            },
            {
              accessor: 'finishForecast',
              title: 'Prazo de finalização',
              render: ({ finishForecast }) =>
                finishForecast &&
                formatLocale(finishForecast, 'DD/MM/YY HH:mm'),
              width: 110,
            },
            {
              accessor: 'finishedAt',
              title: 'Finalizado em',
              render: ({ finishedAt }) =>
                finishedAt && formatLocale(finishedAt, 'DD/MM/YY HH:mm'),
              width: 110,
            },
          ]}
        />
      </Flex>
    </Page>
  );
}
