import { NavLink, ThemeIcon } from '@mantine/core';
import { IconCheckbox } from '@tabler/icons';
import { useSelector } from 'react-redux';
import { UserRole } from '../../../models/user';
import { RootState } from '../../../providers/store';
import { AppRoutePaths } from '../../../utils/enums';

interface ApprovalsLinkProps {
  navigate(to: string): void;
  themeColor: 'dark' | 'light';
}

export function ApprovalsLink({ navigate, themeColor }: ApprovalsLinkProps) {
  const { user } = useSelector((state: RootState) => state.auth);

  return (
    <NavLink
      styles={{
        label: {
          color: themeColor === 'light' ? '#373A40' : '#FBFBFB',
        },
      }}
      label="Aprovações"
      fw="bold"
      color=""
      icon={
        <ThemeIcon color="orange" variant="light">
          <IconCheckbox size={16} />
        </ThemeIcon>
      }
      childrenOffset={28}
    >
      {user?.role && [UserRole.VENDOR].includes(user?.role) && (
        <>
          <NavLink
            label="Primeira peça"
            onClick={() => navigate(AppRoutePaths.ORDERS_FIRST_PIECE)}
          />
          <NavLink
            label="Layouts"
            onClick={() => navigate(AppRoutePaths.ACTIVITY_LAYOUTS)}
          />
        </>
      )}
    </NavLink>
  );
}
