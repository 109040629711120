import { Badge, Button, Card, Flex, Image, Text } from '@mantine/core';
import { IconFile } from '@tabler/icons';
import axios from 'axios';
import { OrderProductionApprovalFile } from '../../models/order-production-file';
import { formatLocale } from '../../providers/dayjs-plugins';
import {
  orderProductionApprovalStatusColor,
  orderProductionApprovalStatusHumanized,
} from '../../utils/constants/order-production-approval';
import { OrderProductionApproval } from '../../models/order-production-approval';

interface ActivityProductionProps {
  orderProductionApproval: OrderProductionApproval;
  files: OrderProductionApprovalFile[] | undefined;
}

async function downloadFile(fileUrl: string, fileName: string) {
  const { data: blob } = await axios.get(fileUrl, {
    responseType: 'blob',
    headers: {},
  });
  const url = URL.createObjectURL(new Blob([blob]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  URL.revokeObjectURL(url);
  document.body.removeChild(link);
}

export function OrderProductionFirstPieceItem({
  orderProductionApproval,
  files,
}: ActivityProductionProps) {
  return (
    <Card mt={16} withBorder>
      <Flex justify="space-between">
        <Text fw="bold">Arquivo Primeira Peça</Text>
        <Badge
          maw={160}
          color={
            orderProductionApprovalStatusColor[orderProductionApproval?.status]
          }
        >
          {
            orderProductionApprovalStatusHumanized[
              orderProductionApproval?.status
            ]
          }
        </Badge>
      </Flex>
      <Flex justify="space-between">
        <Text>Avaliado por: {orderProductionApproval?.approvedBy}</Text>
        <Text>
          {orderProductionApproval.approvedAt &&
            formatLocale(orderProductionApproval.approvedAt, 'DD/MM/YY HH:mm')}
        </Text>
      </Flex>
      <Text>Obs: {orderProductionApproval?.approvalNote}</Text>
      <Text fw="bold" mt={16}>
        Arquivos
      </Text>
      <Flex wrap="wrap" gap={'xs'}>
        {files?.map((item) => (
          <Card key={item.file} bg="gray.1" withBorder w={210} h={250}>
            <Image
              height={100}
              src={item.fileUrl}
              fit="cover"
              withPlaceholder
            />
            <Flex mt={10} align="center">
              <IconFile size={14} style={{ marginRight: 5 }} />
              <Button
                variant="subtle"
                onClick={() => downloadFile(item.fileUrl, item.fileName)}
              >
                <Text
                  variant="link"
                  style={{
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    maxWidth: 100,
                    display: 'inline-block',
                  }}
                >
                  {item.fileName}
                </Text>
              </Button>
            </Flex>
            <Text>por: {item.ownerName}</Text>
            <Text>{formatLocale(item.createdAt, 'DD/MM/YY HH:mm')}</Text>
          </Card>
        ))}
      </Flex>
      <Text mt={16} mr={6} fw={'bold'}>
        Obs Técnico:
      </Text>
      <Text size={'sm'}>{orderProductionApproval?.technicalNote}</Text>
    </Card>
  );
}
