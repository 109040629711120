import { Checkbox, Group, NumberInput, Radio } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { useEffect } from 'react';

interface TransferFormItemProps {
  form: UseFormReturnType<any>;
}

export function TransferFormItem({ form }: TransferFormItemProps) {
  useEffect(() => {
    form.setValues({
      details: {
        height: '',
        width: '',
        hasSample: false,
        personalizationSides: [],
      },
    });
  }, []);

  return (
    <>
      <Group grow>
        <NumberInput
          label="Altura"
          placeholder="em cm*"
          mb={16}
          min={0}
          name="height"
          precision={2}
          step={0.5}
          {...form.getInputProps('details.height')}
        />
        <NumberInput
          label="Largura"
          placeholder="em cm*"
          mb={16}
          min={0}
          name="width"
          precision={2}
          step={0.5}
          {...form.getInputProps('details.width')}
        />
      </Group>
      <Checkbox
        label="Tem manuseio de aplicação ?"
        description="aplicação no produto acima"
        color="green"
        name="hasManipulation"
        mb={16}
        {...form.getInputProps('details.hasManipulation')}
      />
      <Checkbox.Group
        defaultValue={['frente']}
        label="Local da gravação"
        description="você pode selecionar mais de um"
        withAsterisk
        name="personalizationSides"
        mb={16}
        {...form.getInputProps('details.personalizationSides')}
      >
        <Checkbox value="frente" label="Frente" color="green" />
        <Checkbox value="verso" label="Verso" color="green" />
      </Checkbox.Group>
      <Radio.Group
        label="Tipo de produção"
        description="selecione o tipo de produção para este pedido"
        withAsterisk
        mb={16}
        {...form.getInputProps('productionType')}
      >
        <Radio value="needAssociation" label="Manuseio" />
        <Radio value="notNeedAssociation" label="Apenas folha" />
      </Radio.Group>
    </>
  );
}
