import { Flex, Navbar, Image, Center, useMantineTheme } from '@mantine/core';
import { IconHome } from '@tabler/icons';
import { useUserConditionalRender } from '../../hooks/use-user-conditonal-render';
import { UserRole } from '../../models/user';
import { AppRoutePaths } from '../../utils/enums';
import { NavbarButton } from '../NavbarButton';
import { CompaniesLink } from './sub-components/companies-link';
import { UsersLink } from './sub-components/users-link';

import { OrdersLink } from './sub-components/orders-link';
import { rootNavigate } from '../CustomRouter';
import { TeamsLink } from './sub-components/teams-link';
import { OrderMaterialsLink } from './sub-components/order-materials-link';
import { FinancialLink } from './sub-components/financial-link';
import { ReportsLink } from './sub-components/reports-link';
import { ActivitiesLink } from './sub-components/activities-link';
import { BillsLink } from './sub-components/bills-link';
import { PurchasesLink } from './sub-components/purchases-link';
import { ActivityLayoutLink } from './sub-components/layouts-link';
import { PostalsLink } from './sub-components/postals-link';
import { ReceiptLink } from './sub-components/receipt-link';
import { appConfig } from '../../config';
import { SacLink } from './sub-components/sac-link';
import { OptionsLink } from './sub-components/options-link';
import { PaymentSlipLink } from './sub-components/payment-slip-link';
import { ServicesLink } from './sub-components/services-link';
import { SegmentationTag } from './sub-components/segmentation-tag-link';
import { ApprovalsLink } from './sub-components/approvals-link';

interface SidebarProps {
  opened: boolean;
  setOpened: React.Dispatch<React.SetStateAction<boolean>>;
}

export function Sidebar({ opened, setOpened }: SidebarProps) {
  const theme = useMantineTheme();
  const { isAdminType, isUserTypeWithRoles, isUserTypeWithRole, isMainUser } =
    useUserConditionalRender();

  function navigate(to: string) {
    rootNavigate(to);
    setOpened(false);
  }

  return (
    <Navbar
      zIndex={10}
      fixed={false}
      p="md"
      hiddenBreakpoint="md"
      hidden={!opened}
      width={{ lg: 210, md: 200 }}
      style={{ overflowY: 'scroll' }}
    >
      <Navbar.Section grow mt="xs">
        <Center>
          <Image
            height={50}
            width={140}
            fit="contain"
            src={appConfig.sidebarImage}
          />
        </Center>
        <Flex direction="column">
          <NavbarButton
            onClick={() => navigate(AppRoutePaths.HOME)}
            color="gray"
            icon={<IconHome size={16} />}
            text="Home"
            textColor={theme.colorScheme === 'light' ? '#373A40' : '#FBFBFB'}
          />
          {isUserTypeWithRoles(
            [
              UserRole.COMPANY,
              UserRole.MANAGER,
              UserRole.OTHER,
              UserRole.PACKER,
              UserRole.RECEPTIONIST,
              UserRole.TECHNICAL,
              UserRole.VENDOR,
              UserRole.FINANCIAL,
              UserRole.HANDLER,
            ],
            <OrdersLink navigate={navigate} themeColor={theme.colorScheme} />,
          )}
          {isAdminType(
            <>
              <OrdersLink navigate={navigate} themeColor={theme.colorScheme} />
              <UsersLink navigate={navigate} themeColor={theme.colorScheme} />
              <CompaniesLink
                navigate={navigate}
                themeColor={theme.colorScheme}
              />
              <TeamsLink navigate={navigate} themeColor={theme.colorScheme} />
              <ReportsLink navigate={navigate} themeColor={theme.colorScheme} />
              <OrderMaterialsLink
                navigate={navigate}
                themeColor={theme.colorScheme}
              />
              <OptionsLink navigate={navigate} themeColor={theme.colorScheme} />
              <SacLink navigate={navigate} themeColor={theme.colorScheme} />
              <ServicesLink
                navigate={navigate}
                themeColor={theme.colorScheme}
              />
              <PaymentSlipLink
                navigate={navigate}
                themeColor={theme.colorScheme}
              />
              <FinancialLink
                navigate={navigate}
                themeColor={theme.colorScheme}
              />
            </>,
          )}
          {isUserTypeWithRole(
            UserRole.VENDOR,
            <>
              <ApprovalsLink
                navigate={navigate}
                themeColor={theme.colorScheme}
              />
              {isMainUser(
                <UsersLink
                  navigate={navigate}
                  themeColor={theme.colorScheme}
                />,
              )}
              <CompaniesLink
                navigate={navigate}
                themeColor={theme.colorScheme}
              />
              <ActivitiesLink
                navigate={navigate}
                themeColor={theme.colorScheme}
              />
              <OrderMaterialsLink
                navigate={navigate}
                themeColor={theme.colorScheme}
              />
              <PostalsLink navigate={navigate} themeColor={theme.colorScheme} />
              <ReportsLink navigate={navigate} themeColor={theme.colorScheme} />
              <SacLink navigate={navigate} themeColor={theme.colorScheme} />
              <PaymentSlipLink
                navigate={navigate}
                themeColor={theme.colorScheme}
              />
              <SegmentationTag
                navigate={navigate}
                themeColor={theme.colorScheme}
              />
            </>,
          )}
          {isUserTypeWithRole(
            UserRole.RECEPTIONIST,
            <>
              <CompaniesLink
                navigate={navigate}
                themeColor={theme.colorScheme}
              />
              <OrderMaterialsLink
                navigate={navigate}
                themeColor={theme.colorScheme}
              />
              <PostalsLink navigate={navigate} themeColor={theme.colorScheme} />
              <ReceiptLink navigate={navigate} themeColor={theme.colorScheme} />
              <ReportsLink navigate={navigate} themeColor={theme.colorScheme} />
              <PaymentSlipLink
                navigate={navigate}
                themeColor={theme.colorScheme}
              />
            </>,
          )}
          {isUserTypeWithRole(
            UserRole.FINAL_ART,
            <>
              <ActivitiesLink
                navigate={navigate}
                themeColor={theme.colorScheme}
              />
            </>,
          )}
          {isUserTypeWithRole(
            UserRole.COMPANY,
            <>
              <ActivityLayoutLink
                navigate={navigate}
                themeColor={theme.colorScheme}
              />
              <SacLink navigate={navigate} themeColor={theme.colorScheme} />
              <PaymentSlipLink
                navigate={navigate}
                themeColor={theme.colorScheme}
              />
            </>,
          )}
          {isUserTypeWithRole(
            UserRole.FINANCIAL,
            <>
              <FinancialLink
                navigate={navigate}
                themeColor={theme.colorScheme}
              />
              <BillsLink navigate={navigate} themeColor={theme.colorScheme} />
              <PurchasesLink
                navigate={navigate}
                themeColor={theme.colorScheme}
              />
              <CompaniesLink
                navigate={navigate}
                themeColor={theme.colorScheme}
              />
              <ReportsLink navigate={navigate} themeColor={theme.colorScheme} />
              <PostalsLink navigate={navigate} themeColor={theme.colorScheme} />
              <ReceiptLink navigate={navigate} themeColor={theme.colorScheme} />
              <PaymentSlipLink
                navigate={navigate}
                themeColor={theme.colorScheme}
              />
            </>,
          )}
          {isUserTypeWithRole(
            UserRole.HANDLER,
            <>
              <ReportsLink navigate={navigate} themeColor={theme.colorScheme} />
            </>,
          )}
          {isUserTypeWithRole(
            UserRole.MANAGER,
            <>
              <ReportsLink navigate={navigate} themeColor={theme.colorScheme} />
            </>,
          )}
          {isUserTypeWithRole(
            UserRole.PACKER,
            <>
              <ReportsLink navigate={navigate} themeColor={theme.colorScheme} />
            </>,
          )}
          {isUserTypeWithRole(
            UserRole.TECHNICAL,
            <>
              <ReportsLink navigate={navigate} themeColor={theme.colorScheme} />
            </>,
          )}
          {isUserTypeWithRole(
            UserRole.OTHER,
            <>
              <ReportsLink navigate={navigate} themeColor={theme.colorScheme} />
            </>,
          )}
        </Flex>
      </Navbar.Section>
    </Navbar>
  );
}
