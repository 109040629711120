import { useForm } from '@mantine/form';
import { Page } from '../../components/Page';
import { UserRole } from '../../models/user';

import { Button, Flex, Group, Modal, Textarea } from '@mantine/core';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { CustomLoader } from '../../components/CustomLoader';
import { OrderProductionFirstPieceItem } from '../../components/OrderProduction';
import { useGetCompaniesFilter } from '../../data/hooks/filters';
import {
  useGetOrderProductionFiles,
  useGetOrderProductionFirstPieceToApprove,
  useOrderProductionDoApproval,
} from '../../data/hooks/order-production-approval';
import {
  OrderProductionApproval,
  OrderProductionApprovalStatus,
} from '../../models/order-production-approval';
import { successNotification } from '../../providers/mantine-notifications';
import { RootState } from '../../providers/store';
import { OrderProductionToApproveResponse } from '../../utils/types/data/services/order-productions';
import { VendorProductionApproveListList } from './vendor';

export type OrderFirstPieceApproveFormType = {
  isApproved: boolean;
  vendorNote?: string;
};

export type FilterLayoutsFormType = {
  os?: string;
  company?: string;
  status?: string;
};

export type OrderProductionApproveFormType = {
  isApproved: boolean;
  vendorNote?: string;
};

type PageModalState = 'review-production' | null;

export function OrderFirstPiece() {
  const { user } = useSelector((state: RootState) => state.auth);
  const [selectedOrderProductionApproval, setSelectedOrderProductionApproval] =
    useState<OrderProductionToApproveResponse>();
  const [pageModalVisible, setPageModalVisible] =
    useState<PageModalState>(null);

  const page = useRef(1);
  const limit = useRef(10);

  const { fetch: companiesFilterFetcher, response: companiesFilterData } =
    useGetCompaniesFilter();

  const {
    fetch: orderProductionFirstPieceToApproveFetcher,
    reponseData: orderProductionFirstPieceToApproveData,
    loading: orderProductionFirstPieceToApproveLoading,
  } = useGetOrderProductionFirstPieceToApprove();

  const {
    fetch: getOrderProductionApprovalFilesFetcher,
    loading: getOrderProductionApprovalFilesRequestLoader,
    reponseData: getOrderProductionApprovalFilesRequestData,
  } = useGetOrderProductionFiles();

  const {
    fetch: orderProductionDoApprovalRequestFetcher,
    loading: orderProductionDoApprovalsRequestLoader,
  } = useOrderProductionDoApproval();

  const filterForm = useForm<FilterLayoutsFormType>({
    initialValues: {
      os: '',
      company: '',
      status: '',
    },
  });

  const approveOrderProductionForm = useForm<OrderProductionApproveFormType>({
    initialValues: {
      isApproved: false,
      vendorNote: '',
    },
  });

  async function handleSelectOrderProductionApproval(
    item: OrderProductionToApproveResponse,
  ) {
    setSelectedOrderProductionApproval(item);
    if (item) {
      await getLastOrderProductionFiles(item.id);
    }
    setPageModalVisible('review-production');
  }

  async function getLastOrderProductionFiles(id: number) {
    getOrderProductionApprovalFilesFetcher({
      orderProductionApprovalId: id,
    });
  }

  async function handleOrderProductionDoApprovalFetcher(
    values: typeof approveOrderProductionForm.values,
  ) {
    if (!selectedOrderProductionApproval) return;
    await orderProductionDoApprovalRequestFetcher({
      id: selectedOrderProductionApproval?.id,
      data: {
        approval: values.isApproved,
        approvalNote: values.vendorNote,
      },
      onSuccess: () => {
        getOrderFirstPiecePaginated();
        handleCloseModel();
        successNotification({
          title: 'Tudo certo!',
          message: 'Peça avaliada com sucesso ✅',
        });
      },
    });
  }

  async function getOrderFirstPiecePaginated(isReset = false) {
    let validatedFilter: any = {};

    Object.entries(filterForm.values).forEach(([key, val]) => {
      if (val !== '' && val !== null && val !== undefined) {
        validatedFilter[key] = val;
      }
    });

    if (isReset) {
      validatedFilter = {};
    }

    orderProductionFirstPieceToApproveFetcher({
      query: {
        page: page.current,
        limit: limit.current,
        ...validatedFilter,
      },
    });
  }

  function handlePaginate(newPage: number) {
    page.current = newPage;
    getOrderFirstPiecePaginated();
  }

  function handleFilter() {
    page.current = 1;
    limit.current = 10;
    getOrderFirstPiecePaginated();
  }

  function handleClear() {
    page.current = 1;
    limit.current = 10;
    filterForm.reset();
    getOrderFirstPiecePaginated(true);
  }

  function handleCloseModel() {
    setPageModalVisible(null);
    setSelectedOrderProductionApproval(undefined);
    approveOrderProductionForm.reset();
  }

  useEffect(() => {
    companiesFilterFetcher({});
    getOrderFirstPiecePaginated();
  }, []);

  return (
    <Page title="Primeira Peça">
      <CustomLoader loading={getOrderProductionApprovalFilesRequestLoader} />
      {user?.role === UserRole.VENDOR && (
        <VendorProductionApproveListList
          filterForm={filterForm}
          loading={orderProductionFirstPieceToApproveLoading}
          companiesFilter={companiesFilterData || []}
          handleSelectOrderProductionApproval={
            handleSelectOrderProductionApproval
          }
          handleClear={handleClear}
          handleFilter={handleFilter}
          handlePaginate={handlePaginate}
          page={page}
          records={orderProductionFirstPieceToApproveData}
        />
      )}

      <Modal
        size={520}
        opened={pageModalVisible === 'review-production'}
        onClose={() => handleCloseModel()}
        title="Avaliar Produção"
      >
        {selectedOrderProductionApproval && (
          <>
            <OrderProductionFirstPieceItem
              orderProductionApproval={
                selectedOrderProductionApproval as OrderProductionApproval
              }
              files={getOrderProductionApprovalFilesRequestData || []}
            />
            {selectedOrderProductionApproval.status !==
              OrderProductionApprovalStatus.APPROVED &&
              selectedOrderProductionApproval.status !==
                OrderProductionApprovalStatus.REPROVED && (
                <>
                  <form
                    onSubmit={approveOrderProductionForm.onSubmit(
                      handleOrderProductionDoApprovalFetcher,
                    )}
                  >
                    <Flex direction="column">
                      <Textarea
                        required
                        withAsterisk
                        maxLength={255}
                        mt={16}
                        label="Observações"
                        placeholder="escreva aqui qualquer observação de aprovação/reprovação..."
                        name="vendorNote"
                        {...approveOrderProductionForm.getInputProps(
                          'vendorNote',
                        )}
                      />
                      <Group position="right" mt={8}>
                        <Button
                          w={100}
                          variant="light"
                          type="submit"
                          onClick={() => {
                            approveOrderProductionForm.setFieldValue(
                              'isApproved',
                              false,
                            );
                          }}
                          color="red"
                          loading={orderProductionDoApprovalsRequestLoader}
                        >
                          Reprovar
                        </Button>
                        <Button
                          w={100}
                          variant="light"
                          type="submit"
                          color="green"
                          onClick={() => {
                            approveOrderProductionForm.setFieldValue(
                              'isApproved',
                              true,
                            );
                          }}
                          loading={orderProductionDoApprovalsRequestLoader}
                        >
                          Aprovar
                        </Button>
                      </Group>
                    </Flex>
                  </form>
                </>
              )}
          </>
        )}
      </Modal>
    </Page>
  );
}
