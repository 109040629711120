import { AxiosResponse } from 'axios';

import { Notification } from '../../models/notification';
import { api } from '../../providers/base-api';
import { SetNotificationsReadData } from '../../utils/types/data/services/notifications';

export async function getNotificationsRequest(): Promise<
  AxiosResponse<Notification[]>
> {
  const response = await api.get<Notification[]>(`notifications`);

  return response;
}

export async function readNotificationsRequest(
  data: SetNotificationsReadData,
): Promise<AxiosResponse<void>> {
  const response = await api.post<void>(`notifications/read`, data);

  return response;
}

export async function readAllNotificationsRequest(): Promise<
  AxiosResponse<void>
> {
  const response = await api.post<void>(`notifications/read-all`);

  return response;
}
