import { AxiosResponse } from 'axios';

import { api } from '../../providers/base-api';
import {
  OrderProductionApprovalPaginatedParams,
  OrderProductionApprovalsRequestData,
  RequestOrderProductionApprovalData,
} from '../../utils/types/data/services/order-productions';
import {
  OrderProducitonApprovalPaginatedResponse,
  OrderProductionApproval,
} from '../../models/order-production-approval';
import { OrderProductionApprovalFile } from '../../models/order-production-file';

export async function orderProductionApprovalsRequest(
  orderProductionApprovalId: number,
  data: OrderProductionApprovalsRequestData,
): Promise<AxiosResponse<void>> {
  const response = await api.put<void>(
    `order-production-approvals/${orderProductionApprovalId}/approval`,
    data,
  );

  return response;
}

export async function listOrderProductionToApprovalsRequest(
  params: OrderProductionApprovalPaginatedParams,
): Promise<AxiosResponse<OrderProducitonApprovalPaginatedResponse>> {
  const response = await api.get<OrderProducitonApprovalPaginatedResponse>(
    `order-production-approvals`,
    { params },
  );

  return response;
}

export async function requestOrderProductionApproval(
  id: number,
  data: RequestOrderProductionApprovalData,
): Promise<AxiosResponse<void>> {
  const response = await api.post<void>(
    `order-production-approvals/${id}`,
    data,
  );

  return response;
}

export async function orderProductionApprovalListFiles(
  orderProductionApprovalId: number,
): Promise<AxiosResponse<OrderProductionApprovalFile[]>> {
  const response = await api.get<OrderProductionApprovalFile[]>(
    `order-production-approvals/${orderProductionApprovalId}/files`,
  );

  return response;
}

export async function getLastorderProductionApproval(
  orderId: number,
): Promise<AxiosResponse<OrderProductionApproval>> {
  const response = await api.get<OrderProductionApproval>(
    `order-production-approvals/${orderId}/last`,
  );

  return response;
}
