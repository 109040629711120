import { useForm } from '@mantine/form';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CustomLoader } from '../../components/CustomLoader';
import { Page } from '../../components/Page';
import { useGetActivities } from '../../data/hooks/activities';
import {
  getFilterCompaniesListRequest,
  getFilterTeamsListRequest,
  getFilterUsersListRequest,
} from '../../data/services/filters';
import { TeamType } from '../../models/team';
import { UserRole } from '../../models/user';
import { errorNotification } from '../../providers/mantine-notifications';
import { RootState } from '../../providers/store';
import {
  GetFilterCompaniesListResponse,
  GetFilterTeamsListResponse,
  GetFilterUsersListResponse,
} from '../../utils/types/data/services/filters';

import { FinalArtActivityListWeb } from './final-art/web';
import { VendorActivityListWeb } from './vendor/web';
import { useUrlParams } from '../../hooks/use-url-params';

export function ActivityList() {
  const navigation = useNavigate();
  const [companyList, setCompanyList] = useState<
    GetFilterCompaniesListResponse[]
  >([]);
  const [pageLoading, setPageLoading] = useState<boolean>(false);
  const [filterUserList, setFilterUserList] = useState<
    GetFilterUsersListResponse[]
  >([]);
  const [teamList, setTeamList] = useState<GetFilterTeamsListResponse[]>([]);
  const { user } = useSelector((state: RootState) => state.auth);
  const { fetch, loading, reponseData } = useGetActivities();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentPage = useRef(Number(searchParams.get('page')) || 1);

  const formFilter = useForm({
    initialValues: {
      os: searchParams.get('os') || '',
      company: Number(searchParams.get('company')) || '',
      page: searchParams.get('page') || 1,
      limit: searchParams.get('limit') || 10,
      target: Number(searchParams.get('target')) || '',
      createdAt: searchParams.get('createdAt') || null,
      finishedAt: searchParams.get('finishedAt') || null,
      showDone: searchParams.get('showDone') === 'true' ? true : false,
      team: Number(searchParams.get('team')) || '',
      status: searchParams.get('status') || '',
      internalNumber: searchParams.get('internalNumber') || '',
    },
  });

  useUrlParams(formFilter, currentPage, searchParams, setSearchParams);

  async function getCompanies() {
    try {
      const response = await getFilterCompaniesListRequest({});
      setCompanyList(response);
    } catch (error) {
      errorNotification({
        title: 'Erro ao buscar clientes',
        message: 'tente novamente!',
      });
    }
  }

  async function getArtUser() {
    try {
      setPageLoading(true);
      const response = await getFilterUsersListRequest({
        role: UserRole.FINAL_ART,
      });
      setFilterUserList(response);
      setPageLoading(false);
    } catch (error) {
      setPageLoading(false);
      errorNotification({
        title: 'Erro ao buscar usuários.',
        message: 'tente novamente',
      });
    }
  }

  async function getVendorTeam() {
    try {
      setPageLoading(true);
      const response = await getFilterTeamsListRequest({
        type: TeamType.VENDOR,
      });
      setTeamList(response.data);
      setPageLoading(false);
    } catch (error) {
      setPageLoading(false);
      errorNotification({
        title: 'Erro ao buscar usuários.',
        message: 'tente novamente',
      });
    }
  }

  function handleSubmit() {
    currentPage.current = 1;
    getOrderInvoicesPaginated();
  }

  function handlePaginate(page: number) {
    currentPage.current = page;
    getOrderInvoicesPaginated();
  }

  function handleClearFilter() {
    setSearchParams(new URLSearchParams());
    Object.entries(formFilter.values).forEach(([key]) => {
      if (key !== 'page') {
        formFilter.setFieldValue(key, '');
      }
    });
    currentPage.current = 1;
    getOrderInvoicesPaginated(true);
  }

  async function getOrderInvoicesPaginated(isReset = false) {
    const validatedFilter: any = {};

    Object.entries(formFilter.values).forEach(([key, val]) => {
      if (val !== '' && val !== null && val !== undefined) {
        validatedFilter[key] = val;
      }
    });

    try {
      setPageLoading(true);
      if (isReset) {
        fetch({
          page: 1,
          limit: 10,
        });
      } else {
        fetch({
          ...validatedFilter,
          page: currentPage.current,
          limit: 10,
        });
      }
      setPageLoading(false);
    } catch (error) {
      setPageLoading(false);
    }
  }

  useEffect(() => {
    getOrderInvoicesPaginated();
    getVendorTeam();
    getArtUser();
    getCompanies();
  }, []);

  return (
    <Page title="Atividades">
      <CustomLoader loading={pageLoading || loading} />
      {user?.role === UserRole.FINAL_ART && (
        <FinalArtActivityListWeb
          activityList={reponseData?.items || []}
          activityMeta={reponseData?.meta}
          companyList={companyList}
          currentPage={currentPage}
          formFilter={formFilter}
          handleClearFilter={handleClearFilter}
          handlePaginate={handlePaginate}
          handleSubmit={handleSubmit}
          navigation={navigation}
          filterUserList={filterUserList}
          teamList={teamList}
        />
      )}
      {user?.role === UserRole.VENDOR && (
        <VendorActivityListWeb
          activityList={reponseData?.items || []}
          activityMeta={reponseData?.meta}
          companyList={companyList}
          currentPage={currentPage}
          formFilter={formFilter}
          handleClearFilter={handleClearFilter}
          handlePaginate={handlePaginate}
          handleSubmit={handleSubmit}
          filterUserList={filterUserList}
          teamList={teamList}
          navigation={navigation}
        />
      )}
    </Page>
  );
}
