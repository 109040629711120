import { Burger, Flex, MantineProvider, MediaQuery } from '@mantine/core';
import { AppShell, Header } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NotificationsProvider } from '@mantine/notifications';

import { Router } from './routes';
import { RootState } from './providers/store';
import { Sidebar } from './components/Sidebar';
import { useGetNotifications } from './data/hooks/notifications';
import useNotificationsContext from './hooks/use-notifications-context';
import { NotificationIndicator } from './components/NotificationIndicator';
import { UserMenu } from './components/UserMenu';
import { logoutDispatcher, resetLoadingDispatcher } from './store/auth';
import { useNativeNotification } from './hooks/use-native-notification';
import { notificationsRedirectTo } from './utils/notifications-redirect-to';
import { UserRole } from './models/user';
import usePermissionsContext from './hooks/use-permissions-context';

function App() {
  const { signed, user, loading } = useSelector(
    (state: RootState) => state.auth,
  );
  const dispatch = useDispatch<any>();
  const [opened, setOpened] = useState(false);
  const { getNotifications, readNotifications, list, setNotifications } =
    useNotificationsContext();
  const { sendNotification } = useNativeNotification();
  const { fetch: getNotificationFetcher } = useGetNotifications();
  const { getPermissions } = usePermissionsContext();

  async function handleGetNotifications(userRole: UserRole) {
    await getNotificationFetcher({
      onSuccess: (notifications) => {
        if (notifications.length) {
          setNotifications(notifications);
          for (const item of notifications) {
            sendNotification({
              title: item.title,
              message: item.message,
              user: user?.username ?? '',
              to: notificationsRedirectTo(item, userRole),
              callback: () => readNotifications([item.id]),
            });
          }
        }
      },
    });
  }

  useEffect(() => {
    if (signed && user) {
      const intervalId = setInterval(async () => {
        handleGetNotifications(user.role);
      }, Number(process.env.REACT_APP_NOTIFICATION_TIMEOUT_MS));

      return () => clearInterval(intervalId);
    }
  }, [
    signed,
    getNotificationFetcher,
    sendNotification,
    user,
    getNotifications,
  ]);

  useEffect(() => {
    if (user) {
      handleGetNotifications(user.role);
    }
  }, []);

  useEffect(() => {
    if (user) {
      getPermissions();
    }
  }, [user]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      if (loading) {
        dispatch(resetLoadingDispatcher());
      }
    };
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return (
    <MantineProvider
      withGlobalStyles
      withNormalizeCSS
      theme={{
        globalStyles: () => ({
          '::-webkit-scrollbar': {
            width: '10px',
          },
          '::-webkit-scrollbar-track': {
            background: 'transparent',
          },
          '::-webkit-scrollbar-thumb': {
            background: 'rgba(187, 187, 187, 0.5)',
            borderRadius: '6px',
          },
          '::-webkit-scrollbar-thumb:hover': {
            background: 'rgba(187, 187, 187, 0.8)',
          },
        }),
        fontFamily: 'Roboto, sans-serif',
        headings: {
          fontFamily: 'Roboto, sans-serif',
        },
        colorScheme: 'light',
        colors: {
          ltpBlue: [
            '#EAF0FB',
            '#C4D5F3',
            '#9EBAEB',
            '#789FE3',
            '#5285DB',
            '#2C6AD3',
            '#2355A9',
            '#1A3F7F',
            '#112A55',
            '#09152A',
          ],
        },
      }}
    >
      <AppShell
        navbarOffsetBreakpoint="sm"
        asideOffsetBreakpoint="sm"
        navbar={
          signed ? <Sidebar opened={opened} setOpened={setOpened} /> : undefined
        }
        header={
          <Header height={{ base: 50, md: 50 }} p="md">
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                height: '100%',
              }}
            >
              {user && (
                <MediaQuery largerThan="md" styles={{ display: 'none' }}>
                  <Flex justify="space-between" align="center" w="100%">
                    <Burger
                      hidden={!signed}
                      opened={opened}
                      onClick={() => setOpened((o) => !o)}
                      size="sm"
                      mr="xl"
                    />
                    <UserMenu
                      user={user}
                      onLogout={() => dispatch(logoutDispatcher())}
                    />
                  </Flex>
                </MediaQuery>
              )}
              {user && (
                <MediaQuery smallerThan="md" styles={{ display: 'none' }}>
                  <Flex justify="space-between" align="center" w="100%">
                    <Burger
                      color="none"
                      hidden={!signed}
                      opened={opened}
                      onClick={() => setOpened((o) => !o)}
                      size="sm"
                      mr="xl"
                    />
                    <NotificationIndicator
                      getNotifications={getNotifications}
                      onRead={(id) => readNotifications([id])}
                      notifications={list}
                      user={user}
                    />
                    <UserMenu
                      user={user}
                      onLogout={() => dispatch(logoutDispatcher())}
                    />
                  </Flex>
                </MediaQuery>
              )}
            </div>
          </Header>
        }
      >
        <NotificationsProvider position="top-right">
          <Router />
        </NotificationsProvider>
      </AppShell>
    </MantineProvider>
  );
}

export default App;
