import { PaginatedResponse } from '../utils/types';

export enum OrderProductionApprovalStatus {
  PENDING = 'pending',
  APPROVED = 'approved',
  REPROVED = 'reproved',
}

export type OrderProductionApproval = {
  id: number;
  order: number;
  technical: string;
  approvedBy: string;
  status: OrderProductionApprovalStatus;
  approvalNote: string;
  technicalNote: string;
  createdAt: string;
  approvedAt: string;
  os: string;
  product: string;
  productMaterial: string;
  productColor: string;
  engravingText: string;
  teamName: string;
  companyName: string;
};

export type OrderProductionApprovalFile = {
  orderProductionApproval: number;
  file: number;
  ownerName: string;
  createdAt: string;
  fileUrl: string;
  fileName: string;
};

export type OrderProducitonApprovalPaginatedResponse =
  PaginatedResponse<OrderProductionApproval>;
