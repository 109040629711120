import { useFetch } from '../../../hooks/use-fetch';
import { Company } from '../../../models/company';
import { errorNotification } from '../../../providers/mantine-notifications';
import { translateServerHttpErrors } from '../../../utils/helpers';
import { PaginatedResponse } from '../../../utils/types';
import {
  UseCompanyTagListProps,
  UseCreateCompanyProps,
  UseGetCompanyProps,
  UseGetCompanyUsersProps,
  UseGetUserCompaniesProps,
  UseGetViewCompanyDocTagProps,
  UseRemoveUserCompanyProps,
  UseSetCompanyProtestedProps,
  UseSetCompanyUsersProps,
  UseSetUserCompanyEmailPrivacyProps,
  UseUpdateCompanyProps,
} from '../../../utils/types/data/hooks/companies';
import {
  GetCompanyTagListResponse,
  GetCompanyUserResponseData,
  GetUserCompaniesResponseData,
  GetViewCompanyDocTagListResponse,
} from '../../../utils/types/data/services/companies';
import {
  createCompanyRequest,
  getCompanyRequest,
  getCompanyTagListRequest,
  getCompanyUsersRequest,
  getUserCompaniesRequest,
  removeUserCompanyRequest,
  setCompanyProtestedRequest,
  setCompanyUsersRequest,
  setUserCompaniesEmailPrivacyRequest,
  updateCompanyRequest,
  viewCompanyDocTagListRequest,
} from '../../services/companies';

export function useCreateCompany() {
  const {
    fetchData,
    state: { data: reponseData, error, loading },
  } = useFetch<Company>();

  const fetch = ({ data, onSuccess }: UseCreateCompanyProps) =>
    fetchData({
      fetcher: () => createCompanyRequest(data),
      onError: (err: any) => {
        errorNotification({
          title: translateServerHttpErrors(err, 'Erro ao cadastrar empresa.'),
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    reponseData,
    error,
    loading,
  };
}

export function useUpdateCompany() {
  const {
    fetchData,
    state: { data: reponseData, error, loading },
  } = useFetch<Company>();

  const fetch = ({ id, data, onSuccess }: UseUpdateCompanyProps) =>
    fetchData({
      fetcher: () => updateCompanyRequest(id, data),
      onError: () => {
        errorNotification({
          title: 'Erro ao atualizar empresa.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    reponseData,
    error,
    loading,
  };
}

export function useGetCompany() {
  const {
    fetchData,
    state: { data: reponseData, error, loading },
  } = useFetch<Company>();

  const fetch = ({ id }: UseGetCompanyProps) =>
    fetchData({
      fetcher: () => getCompanyRequest(id),
      onError: () => {
        errorNotification({
          title: 'Erro ao buscar empresa.',
          message: 'tente novamente.',
        });
      },
    });

  return {
    fetch,
    reponseData,
    error,
    loading,
  };
}

export function useSetCompanyProtested() {
  const {
    fetchData,
    state: { data: reponse, error, loading },
  } = useFetch<void>();

  const fetch = ({ id, data, onSuccess }: UseSetCompanyProtestedProps) =>
    fetchData({
      fetcher: () => setCompanyProtestedRequest(id, data),
      onError: () => {
        errorNotification({
          title: 'Erro ao atualizar.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    reponse,
    error,
    loading,
  };
}

export function useSetCompanyUsers() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<void>();

  const fetch = ({ companyId, data, onSuccess }: UseSetCompanyUsersProps) =>
    fetchData({
      fetcher: () => setCompanyUsersRequest(companyId, data),
      onError: () => {
        errorNotification({
          title: 'Erro ao atualizar usuários.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useGetCompanyUsers() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<GetCompanyUserResponseData[]>();

  const fetch = ({ companyId, onSuccess }: UseGetCompanyUsersProps) =>
    fetchData({
      fetcher: () => getCompanyUsersRequest(companyId),
      onError: () => {
        errorNotification({
          title: 'Erro ao buscar usuários.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useGetUserCompanies() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<GetUserCompaniesResponseData[]>();

  const fetch = ({ userId, onSuccess }: UseGetUserCompaniesProps) =>
    fetchData({
      fetcher: () => getUserCompaniesRequest(userId),
      onError: () => {
        errorNotification({
          title: 'Erro ao buscar empresas.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useSetGetUserCompanyEmailPrivacy() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<void>();

  const fetch = ({
    userId,
    companyId,
    data,
    onSuccess,
  }: UseSetUserCompanyEmailPrivacyProps) =>
    fetchData({
      fetcher: () =>
        setUserCompaniesEmailPrivacyRequest(userId, companyId, data),
      onError: () => {
        errorNotification({
          title: 'Erro ao atualizar.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useRemoveUserCompany() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<void>();

  const fetch = ({ userId, companyId, onSuccess }: UseRemoveUserCompanyProps) =>
    fetchData({
      fetcher: () => removeUserCompanyRequest(userId, companyId),
      onError: () => {
        errorNotification({
          title: 'Erro ao remover usuário.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useGetViewCompanyDocTag() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<PaginatedResponse<GetViewCompanyDocTagListResponse>>();

  const fetch = ({ query, onSuccess }: UseGetViewCompanyDocTagProps) =>
    fetchData({
      fetcher: () => viewCompanyDocTagListRequest(query),
      onError: () => {
        errorNotification({
          title: 'Erro ao buscar empresas.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useGetCompanyTagList() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<GetCompanyTagListResponse[]>();

  const fetch = ({ companyId, onSuccess }: UseCompanyTagListProps) =>
    fetchData({
      fetcher: () => getCompanyTagListRequest(companyId),
      onError: () => {
        errorNotification({
          title: 'Erro ao buscar tags.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}
