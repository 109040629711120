import { AxiosResponse } from 'axios';
import { api } from '../../providers/base-api';
import {
  CreateSegmentationTagRequestData,
  ListSegmentationTagsResponse,
  UpdateSegmentationTagRequestData,
} from '../../utils/types/data/services/segmentation-tags';

export async function createSegmentationTagRequest(
  data: CreateSegmentationTagRequestData,
): Promise<AxiosResponse<void>> {
  const response = await api.post<void>(`segmentation-tags`, data);

  return response;
}

export async function updateSegmentationTagRequest(
  id: number,
  data: UpdateSegmentationTagRequestData,
): Promise<AxiosResponse<void>> {
  const response = await api.put<void>(`segmentation-tags/${id}`, data);

  return response;
}

export async function listSegmentationTagsRequest(): Promise<
  AxiosResponse<ListSegmentationTagsResponse[]>
> {
  const response = await api.get<ListSegmentationTagsResponse[]>(
    `segmentation-tags`,
  );

  return response;
}
