import { AxiosResponse } from 'axios';
import { Company } from '../../models/company';
import { api } from '../../providers/base-api';
import { PaginatedResponse } from '../../utils/types';
import {
  CreateCompanyRequestData,
  GetCompanyTagListResponse,
  GetCompanyUserResponseData,
  GetUserCompaniesResponseData,
  GetViewCompanyDocTagListRequestQuery,
  GetViewCompanyDocTagListResponse,
  SetCompanyProtestedRequestData,
  SetCompanyUsersRequestData,
  SetUserCompanyEmailPrivacyRequestData,
  UpdateCompanyRequestData,
} from '../../utils/types/data/services/companies';

export type CompaniesResponse = Company[];

export type GetCompaniesPaginatedQuery = {
  page: number;
  limit: number;
  nomeFantasia?: string;
  name?: string;
  team?: number;
  isProtested?: boolean;
  createdAt?: Date;
};

export async function createCompanyRequest(
  data: CreateCompanyRequestData,
): Promise<AxiosResponse<Company>> {
  const response = await api.post<Company>('companies', data);

  return response;
}

export async function updateCompanyRequest(
  id: number,
  data: UpdateCompanyRequestData,
): Promise<AxiosResponse<Company>> {
  const response = await api.put<Company>(`companies/${id}`, data);

  return response;
}

export async function getCompaniesRequest(
  query: GetCompaniesPaginatedQuery,
): Promise<PaginatedResponse<Company>> {
  const response = await api.get<PaginatedResponse<Company>>('companies', {
    params: query,
  });

  return response.data;
}

export async function getCompanyRequest(
  id: number,
): Promise<AxiosResponse<Company>> {
  const response = await api.get<Company>(`companies/${id}`);

  return response;
}

export async function getTeamCompaniesRequest(
  id: number,
): Promise<CompaniesResponse> {
  const response = await api.get<Company[]>(`companies/team/${id}`);

  return response.data;
}

export async function setCompanyProtestedRequest(
  id: number,
  data: SetCompanyProtestedRequestData,
): Promise<AxiosResponse<void>> {
  const response = await api.put<void>(`companies/protested/${id}`, data);

  return response;
}

export async function setCompanyUsersRequest(
  companyId: number,
  data: SetCompanyUsersRequestData,
): Promise<AxiosResponse<void>> {
  const response = await api.post<void>(`companies/users/${companyId}`, data);

  return response;
}

export async function getCompanyUsersRequest(
  companyId: number,
): Promise<AxiosResponse<GetCompanyUserResponseData[]>> {
  const response = await api.get<GetCompanyUserResponseData[]>(
    `companies/users/${companyId}`,
  );

  return response;
}

export async function getUserCompaniesRequest(
  userId: number,
): Promise<AxiosResponse<GetUserCompaniesResponseData[]>> {
  const response = await api.get<GetUserCompaniesResponseData[]>(
    `companies/user-companies/${userId}`,
  );

  return response;
}

export async function setUserCompaniesEmailPrivacyRequest(
  userId: number,
  companyId: number,
  data: SetUserCompanyEmailPrivacyRequestData,
): Promise<AxiosResponse<void>> {
  const response = await api.put<void>(
    `companies/${companyId}/users/${userId}/email-privacy`,
    data,
  );

  return response;
}

export async function removeUserCompanyRequest(
  userId: number,
  companyId: number,
): Promise<AxiosResponse<void>> {
  const response = await api.delete<void>(
    `companies/${companyId}/users/${userId}/remove`,
  );

  return response;
}

export async function viewCompanyDocTagListRequest(
  query: GetViewCompanyDocTagListRequestQuery,
): Promise<AxiosResponse<PaginatedResponse<GetViewCompanyDocTagListResponse>>> {
  const response = await api.get<
    PaginatedResponse<GetViewCompanyDocTagListResponse>
  >(`companies/by-view`, { params: query });

  return response;
}

export async function getCompanyTagListRequest(
  companyId: number,
): Promise<AxiosResponse<GetCompanyTagListResponse[]>> {
  const response = await api.get<GetCompanyTagListResponse[]>(
    `companies/${companyId}/tags`,
  );

  return response;
}
