import { NavLink, ThemeIcon } from '@mantine/core';
import { IconTag } from '@tabler/icons';
import { AppRoutePaths } from '../../../utils/enums';

interface SegmentationTagLinkProps {
  navigate(to: string): void;
  themeColor: 'dark' | 'light';
}

export function SegmentationTag({
  navigate,
  themeColor,
}: SegmentationTagLinkProps) {
  return (
    <NavLink
      styles={{
        label: {
          color: themeColor === 'light' ? '#373A40' : '#FBFBFB',
        },
      }}
      label="Seg. Tags"
      fw="bold"
      color=""
      icon={
        <ThemeIcon color="blue" variant="light">
          <IconTag size={16} />
        </ThemeIcon>
      }
      childrenOffset={28}
    >
      <NavLink
        label="Listagem"
        onClick={() => navigate(AppRoutePaths.SEGMENTATION_TAG_LIST)}
      />
    </NavLink>
  );
}
