import {
  Badge,
  Button,
  Flex,
  Group,
  Indicator,
  Menu,
  Select,
  TextInput,
} from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import {
  IconCirclePlus,
  IconCopy,
  IconDotsVertical,
  IconEdit,
  IconEye,
  IconFile,
} from '@tabler/icons';
import { DataTable } from 'mantine-datatable';
import { NavigateFunction } from 'react-router-dom';
import { FormFilterType } from '..';
import { CustomDatePicker } from '../../../components/CustomDatePicker';
import {
  FinancialStatus,
  Order,
  Order as OrderModel,
  OrderStatus,
} from '../../../models/order';
import { LayoutStatus } from '../../../models/order-layout';
import { dayjsPlugins, formatLocale } from '../../../providers/dayjs-plugins';
import {
  orderStatusColor,
  orderStatusHumanized,
  statusList,
} from '../../../utils/constants';
import {
  financialStatusColor,
  financialStatusTypeHumanized,
  orderSortList,
} from '../../../utils/constants/order';
import { AppRoutePaths } from '../../../utils/enums';
import { formatBRL, getOrderPrices } from '../../../utils/helpers';
import { PageMeta } from '../../../utils/types';
import { GetUserCompaniesResponseData } from '../../../utils/types/data/services/companies';
import { renderOrderTableRowIcon } from '../functions/render-items';
import { setOrderTableRowBackgroundColor } from '../functions/set-values';
import OrderListPageFilter from '../sub-components/page-filter';

interface CompanyHomeWebProps {
  items: OrderModel[];
  navigation: NavigateFunction;
  handlePaginate(page: number): void;
  handleSubmit(values: FormFilterType): void;
  handleChangePageLimit(limit: number): void;
  currentPage: React.MutableRefObject<number>;
  meta: PageMeta | null;
  formFilter: UseFormReturnType<any>;
  handleClear: () => void;
  setSelectedOrder: React.Dispatch<React.SetStateAction<Order | undefined>>;
  setOrderFilesModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setOrderViewModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  getUserCompaniesData: GetUserCompaniesResponseData[] | undefined;
  setOrderToClone: (order: Order) => void;
  filtersCollapse: boolean;
  setFiltersCollapse: React.Dispatch<React.SetStateAction<boolean>>;
}

export function CompanyHomeWeb({
  items,
  meta,
  handlePaginate,
  currentPage,
  navigation,
  formFilter,
  handleSubmit,
  handleClear,
  setSelectedOrder,
  setOrderFilesModalVisible,
  handleChangePageLimit,
  setOrderViewModalVisible,
  getUserCompaniesData,
  setOrderToClone,
  filtersCollapse,
  setFiltersCollapse,
}: CompanyHomeWebProps) {
  function handleViewOrderFiles(order: Order) {
    setSelectedOrder(order);
    setOrderFilesModalVisible(true);
  }

  function handleViewOrder(order?: Order) {
    setSelectedOrder(order);
    setOrderViewModalVisible(true);
  }

  return (
    <Flex direction="column" w="99%">
      <Flex align="center" justify="end" mb={8}></Flex>
      <Group mb={16} position="right">
        <OrderListPageFilter.Button
          isCollapsed={filtersCollapse}
          onClick={() => setFiltersCollapse(!filtersCollapse)}
        />
        <Button
          color="ltpBlue.9"
          leftIcon={<IconCirclePlus />}
          onClick={() => navigation(AppRoutePaths.CREATE_ORDER)}
        >
          Novo
        </Button>
      </Group>
      <OrderListPageFilter
        formFilter={formFilter}
        handleClear={handleClear}
        handleSubmit={handleSubmit}
        isCollapsed={filtersCollapse}
        customInputs={() => (
          <>
            <Select
              searchable
              name="company"
              label="Empresas"
              placeholder="selecione a empresa"
              data={
                getUserCompaniesData?.map((item) => ({
                  label: item.name,
                  value: item.id,
                })) ?? []
              }
              mb={16}
              mr={8}
              {...formFilter.getInputProps('company')}
            />
            <TextInput
              label="Identificador"
              placeholder="código interno do pedido"
              mb={16}
              mr={8}
              type="text"
              name="internalNumber"
              {...formFilter.getInputProps('internalNumber')}
            />
            <TextInput
              label="OS"
              placeholder="numero da OS"
              mb={16}
              mr={8}
              type="text"
              name="os"
              {...formFilter.getInputProps('os')}
            />
            <Select
              zIndex={100}
              name="status"
              label="Status"
              placeholder="selecione o status do pedido"
              data={statusList}
              mb={16}
              mr={8}
              defaultValue={formFilter.values.status}
              onChange={(values) => {
                formFilter.setFieldValue('status', values?.toString());
              }}
            />
            <CustomDatePicker
              placeholder="selecione uma data"
              label="Data de Retirada"
              lang="br"
              name="receiptForecast"
              mb={16}
              mr={8}
              defaultValue={
                formFilter.values.receiptForecast &&
                new Date(
                  dayjsPlugins(formFilter.values.receiptForecast).toDate(),
                )
              }
              onChange={(value) => {
                value
                  ? formFilter.setFieldValue(
                      'receiptForecast',
                      dayjsPlugins(value).format('YYYY-MM-DD'),
                    )
                  : formFilter.setFieldValue('receiptForecast', '');
              }}
            />
            <CustomDatePicker
              placeholder="selecione uma data"
              label="Prev. de Finalização"
              name="finishForecast"
              mb={16}
              mr={8}
              defaultValue={
                formFilter.values.finishForecast &&
                new Date(
                  dayjsPlugins(formFilter.values.finishForecast).toDate(),
                )
              }
              onChange={(value) => {
                value
                  ? formFilter.setFieldValue(
                      'finishForecast',
                      dayjsPlugins(value).format('YYYY-MM-DD'),
                    )
                  : formFilter.setFieldValue('finishForecast', '');
              }}
            />
            <Select
              name="sortBy"
              label="Ordenar por"
              placeholder="selecione uma ordenação"
              data={orderSortList}
              mb={16}
              mr={8}
              {...formFilter.getInputProps('sortBy')}
            />
          </>
        )}
      />
      <DataTable
        recordsPerPageLabel="Itens por página"
        recordsPerPageOptions={[10, 50, 100, 300]}
        onRecordsPerPageChange={(recordRange) =>
          handleChangePageLimit(recordRange)
        }
        height="75vh"
        noRecordsText="Sem pedidos"
        withBorder
        borderRadius="sm"
        striped
        highlightOnHover
        page={currentPage.current}
        totalRecords={meta?.totalItems}
        recordsPerPage={meta?.itemsPerPage ?? 10}
        onPageChange={handlePaginate}
        records={items}
        rowStyle={(record) =>
          setOrderTableRowBackgroundColor({ ...record, rework: false })
        }
        columns={[
          {
            accessor: 'types',
            title: '',
            render: (order) =>
              renderOrderTableRowIcon({ ...order, rework: false }),
          },
          {
            accessor: 'company.name',
            title: 'Empresa',
          },
          {
            accessor: 'internalNumber',
            title: 'Identificador',
          },
          {
            accessor: 'os',
            title: 'OS',
          },
          {
            accessor: 'status',
            title: 'Status',
            render: ({ status }) => (
              <Badge color={orderStatusColor[status]} mb={6}>
                {orderStatusHumanized[status]}
              </Badge>
            ),
          },
          {
            accessor: 'financialStatus',
            title: 'Status Fiscal',
            render: ({ financialStatus }) =>
              financialStatus && (
                <Badge color={financialStatusColor[financialStatus]} mb={6}>
                  {financialStatusTypeHumanized[financialStatus]}
                </Badge>
              ),
          },
          {
            accessor: 'service.name',
            title: 'Serviço',
          },
          {
            accessor: 'engravingText',
            title: 'Logotipo',
          },
          {
            accessor: 'quantity',
            title: 'Qtde',
            width: 60,
          },
          {
            accessor: 'product',
            title: 'Produto',
          },
          {
            accessor: 'needsPostal',
            title: 'Correio',
            render: ({ needsPostal }) => (
              <Badge color={needsPostal ? 'green' : 'red'} mb={6}>
                {needsPostal ? 'sim' : 'não'}
              </Badge>
            ),
          },
          {
            accessor: 'receiptedAt',
            title: 'Recebido em',
            render: ({ receiptedAt }) =>
              receiptedAt && formatLocale(receiptedAt, 'DD/MM/YY HH:mm'),
            width: 110,
          },
          {
            accessor: 'finishForecast',
            title: 'Prev. Finalização',
            render: ({ finishForecast }) =>
              finishForecast && formatLocale(finishForecast, 'DD/MM/YY HH:mm'),
            width: 110,
          },
          {
            accessor: 'fileLink',
            title: 'Arquivos',
            render: (order) => (
              <Indicator
                disabled={order.layout?.status !== LayoutStatus.PENDING_CLIENT}
                inline
                color="orange"
              >
                <Button
                  color="blue"
                  variant="subtle"
                  onClick={() => handleViewOrderFiles(order)}
                  w={40}
                  p={0}
                >
                  <IconFile />
                </Button>
              </Indicator>
            ),
            width: 110,
          },
          {
            accessor: 'supplier',
            title: 'Fornecedor',
          },
          {
            accessor: 'porter',
            title: 'Portador',
            render: (orderItem) =>
              orderItem.status === OrderStatus.FINISHED
                ? orderItem.dispatchLocation
                : orderItem.carrierName,
          },
          {
            accessor: 'price',
            title: 'Preço (Gravação)',
            render: ({ price }) => formatBRL(price),
          },
          {
            accessor: 'totalPrice',
            title: 'Preço total',
            render: (order) => getOrderPrices(order).totalPrice,
            width: 110,
          },
          {
            accessor: 'company-table-menu',
            title: '',
            render: (orderItem) => (
              <Menu>
                <Menu.Target>
                  <Button color="blue" variant="subtle" w={40} p={0}>
                    <IconDotsVertical />
                  </Button>
                </Menu.Target>
                <Menu.Dropdown style={{ position: 'absolute' }}>
                  <Menu.Item
                    disabled={
                      orderItem.status !== OrderStatus.CREATED ||
                      orderItem.financialStatus === FinancialStatus.INVOICED
                    }
                    onClick={() => navigation(`/order/edit/${orderItem.id}`)}
                    icon={<IconEdit size={14} />}
                  >
                    Editar
                  </Menu.Item>
                  <Menu.Item
                    disabled={
                      !!orderItem.origin ||
                      orderItem.isSample ||
                      orderItem.rework
                    }
                    onClick={() => setOrderToClone(orderItem)}
                    icon={<IconCopy size={14} />}
                  >
                    Criar repetição
                  </Menu.Item>
                  <Menu.Item
                    onClick={() => handleViewOrder(orderItem)}
                    icon={<IconEye size={14} />}
                  >
                    Ver
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            ),
          },
        ]}
      />
    </Flex>
  );
}
