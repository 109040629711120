import {
  Badge,
  Button,
  Flex,
  Group,
  Paper,
  Select,
  TextInput,
} from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { DataTable } from 'mantine-datatable';
import { FilterLayoutsFormType } from '..';
import { formatLocale } from '../../../providers/dayjs-plugins';
import { orderFirstPieceStatusList } from '../../../utils/constants/order';
import { OrderProducitonApprovalPaginatedResponse } from '../../../models/order-production-approval';
import { GetFilterUsersListResponse } from '../../../utils/types/data/services/filters';
import { OrderProductionToApproveResponse } from '../../../utils/types/data/services/order-productions';
import {
  orderProductionApprovalStatusColor,
  orderProductionApprovalStatusHumanized,
} from '../../../utils/constants/order-production-approval';

interface VendorProductionApproveListProps {
  records: OrderProducitonApprovalPaginatedResponse | undefined;
  filterForm: UseFormReturnType<FilterLayoutsFormType>;
  loading: boolean;
  companiesFilter?: GetFilterUsersListResponse[];
  handleSelectOrderProductionApproval: (
    item: OrderProductionToApproveResponse,
  ) => void;
  handleFilter(): void;
  handleClear(): void;
  handlePaginate(newPage: number): void;
  page: React.MutableRefObject<number>;
}

export function VendorProductionApproveListList({
  records,
  filterForm,
  companiesFilter,
  handleSelectOrderProductionApproval,
  handleClear,
  handleFilter,
  handlePaginate,
  page,
  loading,
}: VendorProductionApproveListProps) {
  return (
    <>
      <Flex direction="column" w="99%">
        <form onSubmit={filterForm.onSubmit(handleFilter)}>
          <Paper p={16} mb={16} mt={16} withBorder>
            <Flex wrap="wrap">
              <TextInput
                label="OS"
                placeholder="numero da OS"
                mb={16}
                mr={8}
                type="text"
                name="os"
                {...filterForm.getInputProps('os')}
              />
              <Select
                clearable
                searchable
                name="company"
                label="Cliente"
                placeholder="selecione o cliente"
                data={companiesFilter?.map((item) => ({
                  label: item.name,
                  value: item.id,
                }))}
                mb={16}
                mr={8}
                {...filterForm.getInputProps('company')}
              />
              <Select
                clearable
                searchable
                name="status"
                label="Status"
                placeholder="selecione o status"
                data={orderFirstPieceStatusList}
                mb={16}
                mr={8}
                {...filterForm.getInputProps('status')}
              />
            </Flex>
            <Group>
              <Button
                mt={25}
                color="ltpBlue.9"
                type="button"
                variant="outline"
                onClick={() => handleClear()}
              >
                Limpar
              </Button>
              <Button mt={25} ml={16} color="ltpBlue.9" type="submit">
                Filtrar
              </Button>
            </Group>
          </Paper>
        </form>
        <DataTable
          onRowClick={(record) => handleSelectOrderProductionApproval(record)}
          minHeight={400}
          noRecordsText="Sem produção para aprovar"
          withBorder
          striped
          highlightOnHover
          page={page.current}
          totalRecords={records?.meta.totalItems}
          recordsPerPage={10}
          onPageChange={handlePaginate}
          records={records?.items || []}
          fetching={loading}
          columns={[
            {
              accessor: 'os',
              title: 'OS',
            },
            {
              accessor: 'teamName',
              title: 'Equipe',
            },
            {
              accessor: 'companyName',
              title: 'Cliente',
            },
            {
              accessor: 'productGroup',
              title: 'Produto',
              render: ({ product, productColor, productMaterial }) =>
                `${product}, ${productColor}, ${productMaterial}`,
            },
            {
              accessor: 'engravingText',
              title: 'Logo',
            },
            {
              accessor: 'status',
              title: 'Status',
              render: ({ status }) => (
                <Badge color={orderProductionApprovalStatusColor[status]}>
                  {orderProductionApprovalStatusHumanized[status]}
                </Badge>
              ),
            },
            {
              accessor: 'createdAt',
              title: 'Criado em',
              render: ({ createdAt }) =>
                createdAt && formatLocale(createdAt, 'DD/MM/YY'),
            },
            {
              accessor: 'approval',
              title: 'Atualizado em',
              render: ({ approvedAt }) =>
                approvedAt && formatLocale(approvedAt, 'DD/MM/YY'),
            },
          ]}
        />
      </Flex>
    </>
  );
}
