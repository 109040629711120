import { User } from './user';

export enum NotificationType {
  LAYOUT = 'layout',
  LAYOUT_PRODUCTION = 'layout_production',
  ART_ACTIVITY = 'art_activity',
  PAYMENT_SLIP = 'payment_slip',
  PRODUCTION_APPROVAL = 'production_approval',
}

export type Notification = {
  id: number;
  user: number;
  title: string;
  message: string;
  type: NotificationType;
  data?: Record<string, any>;
  createdAt: Date;
};

export type NotificationPopulated = Omit<Notification, 'user'> & {
  user: User;
};
