import { useFetch } from '../../../hooks/use-fetch';
import { errorNotification } from '../../../providers/mantine-notifications';
import {
  UseCreateSegmentationTagProps,
  UseUpdateSegmentationTagProps,
} from '../../../utils/types/data/hooks/segmentation-tags';
import { ListSegmentationTagsResponse } from '../../../utils/types/data/services/segmentation-tags';
import {
  createSegmentationTagRequest,
  listSegmentationTagsRequest,
  updateSegmentationTagRequest,
} from '../../services/segmentation-tags';

export function useCreateSegmentationTag() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<void>();

  const fetch = ({ data, onSuccess }: UseCreateSegmentationTagProps) =>
    fetchData({
      fetcher: () => createSegmentationTagRequest(data),
      onError: () => {
        errorNotification({
          title: 'Erro ao criar tag.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useUpdateSegmentationTag() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<void>();

  const fetch = ({ id, data, onSuccess }: UseUpdateSegmentationTagProps) =>
    fetchData({
      fetcher: () => updateSegmentationTagRequest(id, data),
      onError: () => {
        errorNotification({
          title: 'Erro ao atualizar tag.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useListSegmentationTag() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<ListSegmentationTagsResponse[]>();

  const fetch = () =>
    fetchData({
      fetcher: () => listSegmentationTagsRequest(),
      onError: () => {
        errorNotification({
          title: 'Erro ao buscar tag.',
          message: 'tente novamente.',
        });
      },
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}
