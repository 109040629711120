import {
  Button,
  Checkbox,
  Flex,
  Group,
  Menu,
  Paper,
  TextInput,
} from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { IconDotsVertical, IconFileText, IconTrash } from '@tabler/icons';
import { DataTable } from 'mantine-datatable';
import { useSelector } from 'react-redux';
import { FormFilterType } from '..';
import { CustomDateRangePicker } from '../../../components/CustomDateRangePicker';
import { UserRole } from '../../../models/user';
import { formatLocale } from '../../../providers/dayjs-plugins';
import { RootState } from '../../../providers/store';
import { formatBRL } from '../../../utils/helpers';
import { PageMeta } from '../../../utils/types';
import { ReceiptResponse } from '../../../utils/types/data/services/receipts';

interface FinancialHomeWebProps {
  items: ReceiptResponse[];
  handlePaginate(page: number): void;
  handleChangePageLimit(limit: number): void;
  handleSubmit(values: FormFilterType): void;
  currentPage: React.MutableRefObject<number>;
  meta: PageMeta | null;
  formFilter: UseFormReturnType<any>;
  handleClear: () => void;
  handleCancelReceipt(): void;
  setSelectedRecepit: React.Dispatch<
    React.SetStateAction<ReceiptResponse | undefined>
  >;
  handleReprintReceipt: (id: number) => void;
}

export function FinancialHomeWeb({
  items,
  handlePaginate,
  handleChangePageLimit,
  handleSubmit,
  currentPage,
  meta,
  formFilter,
  handleClear,
  handleCancelReceipt,
  setSelectedRecepit,
  handleReprintReceipt,
}: FinancialHomeWebProps) {
  const { user } = useSelector((state: RootState) => state.auth);

  function handleSelectedReceiptToCancel(receipt: ReceiptResponse) {
    setSelectedRecepit(receipt);
    handleCancelReceipt();
  }

  return (
    <Flex direction="column" w="99%">
      <form onSubmit={formFilter.onSubmit((values) => handleSubmit(values))}>
        <Paper p={16} mb={16} mt={16} withBorder>
          <Flex wrap="wrap">
            <TextInput
              label="Nº Recibo"
              placeholder="número do Recibo"
              mb={16}
              mr={8}
              type="text"
              name="code_receipt"
              {...formFilter.getInputProps('code_receipt')}
            />
            <TextInput
              label="OS"
              placeholder="numero da OS"
              mb={16}
              mr={8}
              type="text"
              name="os"
              {...formFilter.getInputProps('os')}
            />
            <TextInput
              label="Valor do Recibo"
              placeholder="digite o Valor do Recibo"
              mb={16}
              mr={8}
              type="text"
              name="value_receipt"
              value={formatBRL(formFilter.values.value_receipt)}
              onChange={(e) =>
                formFilter.setValues({
                  value_receipt: formatBRL(e.target.value),
                })
              }
            />
            <CustomDateRangePicker
              allowSingleDateInRange
              label="Criado em"
              placeholder="selecione um intervalo"
              mr={8}
              {...formFilter.getInputProps('createdAt')}
            />
          </Flex>
          <Group>
            <Button
              mt={25}
              color="ltpBlue.9"
              type="button"
              variant="outline"
              onClick={handleClear}
            >
              Limpar
            </Button>
            <Button mt={25} ml={16} color="ltpBlue.9" type="submit">
              Filtrar
            </Button>
            <Checkbox
              mt={40}
              label="Mostrar cancelados"
              checked={formFilter.values.showCancelled}
              {...formFilter.getInputProps('showCancelled')}
            />
          </Group>
        </Paper>
      </form>
      <DataTable
        minHeight={200}
        noRecordsText="Sem recibos"
        withBorder
        borderRadius="sm"
        striped
        highlightOnHover
        height={390}
        page={currentPage.current}
        totalRecords={meta?.totalItems}
        onPageChange={handlePaginate}
        recordsPerPageLabel="Itens por página"
        recordsPerPage={meta?.itemsPerPage ?? 10}
        recordsPerPageOptions={[10, 50, 100, 200]}
        onRecordsPerPageChange={(recordRange: number) =>
          handleChangePageLimit(recordRange)
        }
        records={items}
        columns={[
          {
            accessor: 'code',
            title: 'Código',
          },
          {
            accessor: 'receptionist',
            title: 'Criado por',
          },
          {
            accessor: 'installments',
            title: 'Parcelas',
          },
          {
            accessor: 'value',
            title: 'Valor',
            render: ({ totalValue }) => `${formatBRL(totalValue)}`,
            width: 110,
          },
          {
            accessor: 'obs',
            title: 'Obs',
            width: 410,
          },
          {
            accessor: 'createdAt',
            title: 'Data de Emissão',
            render: ({ createdAt }) =>
              createdAt && formatLocale(createdAt, 'DD/MM/YY'),
            width: 110,
          },
          {
            accessor: 'deletedAt',
            title: 'Cancelado em',
            render: ({ deletedAt }) =>
              deletedAt && formatLocale(deletedAt, 'DD/MM/YY'),
            width: 110,
          },
          {
            accessor: 'menu',
            title: '',
            render: (receipt) => (
              <Menu>
                <Menu.Target>
                  <Button color="blue" variant="subtle" w={40} p={0}>
                    <IconDotsVertical />
                  </Button>
                </Menu.Target>
                <Menu.Dropdown style={{ position: 'absolute' }}>
                  <Menu.Item
                    onClick={() => handleReprintReceipt(receipt.id)}
                    icon={<IconFileText size={14} />}
                  >
                    Imprimir
                  </Menu.Item>
                  <Menu.Item
                    disabled={
                      !!receipt.deletedAt || user?.role !== UserRole.FINANCIAL
                    }
                    onClick={() => handleSelectedReceiptToCancel(receipt)}
                    icon={<IconTrash size={14} />}
                  >
                    Cancelar
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            ),
          },
        ]}
      />
    </Flex>
  );
}
