import { useFetch } from '../../../hooks/use-fetch';
import { Ticket } from '../../../models/ticket';
import { errorNotification } from '../../../providers/mantine-notifications';
import {
  UseCreateTicketProps,
  UseGetTicketProps,
} from '../../../utils/types/data/hooks/tickets';
import { createTicketResquest, getTicketResquest } from '../../services/ticket';

export function useCreateTicket() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<void>();

  const fetch = ({ data, orderId, onSuccess }: UseCreateTicketProps) =>
    fetchData({
      fetcher: () => createTicketResquest(orderId, data),
      onError: () => {
        errorNotification({
          title: 'Erro ao criar ticket.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useGetTicket() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<Ticket>();

  const fetch = ({ ticketId }: UseGetTicketProps) =>
    fetchData({
      fetcher: () => getTicketResquest(ticketId),
      onError: () => {
        errorNotification({
          title: 'Erro ao buscar ticket.',
          message: 'tente novamente.',
        });
      },
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}
