import { Button, Collapse, Flex, Group, Paper } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { IconFilter, IconFilterOff } from '@tabler/icons';
import { ReactNode } from 'react';

type PageFilterProps<FormType> = {
  isCollapsed: boolean;
  formFilter: UseFormReturnType<FormType, (values: FormType) => any>;
  children: ReactNode;
  handleSubmit(values: FormType): void;
  handleClear: () => void;
};

type PageFilterButtonProps = {
  onClick: () => void;
  ml?: number;
  mt?: number;
  mr?: number;
  mb?: number;
  isCollapsed: boolean;
};

const FilterButton = ({ isCollapsed, ...props }: PageFilterButtonProps) => {
  return (
    <Button
      variant="light"
      color="blue"
      leftIcon={isCollapsed ? <IconFilterOff /> : <IconFilter />}
      {...props}
    >
      Filtros
    </Button>
  );
};

function Filter<FormType>({
  formFilter,
  handleClear,
  handleSubmit,
  isCollapsed,
  children,
}: PageFilterProps<FormType>) {
  return (
    <Collapse in={isCollapsed}>
      <form onSubmit={formFilter.onSubmit((values) => handleSubmit(values))}>
        <Paper p={16} mb={16} withBorder>
          <Flex wrap="wrap">{children}</Flex>
          <Group>
            <Button
              mt={25}
              color="ltpBlue.9"
              type="button"
              variant="outline"
              onClick={handleClear}
            >
              Limpar
            </Button>
            <Button mt={25} ml={16} color="ltpBlue.9" type="submit">
              Filtrar
            </Button>
          </Group>
        </Paper>
      </form>
    </Collapse>
  );
}

export default {
  Filter,
  FilterButton,
};
