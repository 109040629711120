import { Grid, Flex, Text } from '@mantine/core';
import { Order } from '../../../models/order';

export type ModalOrderDetailsProps = {
  order?: Order;
};

export function ModalOrderDetails({ order }: ModalOrderDetailsProps) {
  return (
    <Grid gutter="xs" columns={2}>
      <Grid.Col mt={8} mb={8} span={2}>
        <Text color="gray">Info do pedido</Text>
      </Grid.Col>
      <Grid.Col span={1}>
        <Flex direction="column">
          <Text fw="bold">OS</Text>
          <Text color="gray">{order?.os ?? '--'}</Text>
        </Flex>
      </Grid.Col>
      <Grid.Col span={1}>
        <Flex direction="column">
          <Text fw="bold">Identificação interna</Text>
          <Text color="gray">{order?.internalNumber ?? '--'}</Text>
        </Flex>
      </Grid.Col>
      <Grid.Col span={1}>
        <Flex direction="column">
          <Text fw="bold">Serviço</Text>
          <Text color="gray">{order?.service.name ?? '--'}</Text>
        </Flex>
      </Grid.Col>
      <Grid.Col span={1}>
        <Flex direction="column">
          <Text fw="bold">Quantidade</Text>
          <Text color="gray">{order?.quantity ?? '--'}</Text>
        </Flex>
      </Grid.Col>
      <Grid.Col span={2}>
        <Flex direction="column">
          <Text fw="bold">Produto</Text>
          <Text color="gray">
            {`${order?.product}, ${order?.productColor}, ${order?.productMaterial}`}
          </Text>
        </Flex>
      </Grid.Col>
    </Grid>
  );
}
