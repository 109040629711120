import { Checkbox, Group, NumberInput, Radio } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { useEffect } from 'react';

interface EtiquetaResinadaFormItemProps {
  form: UseFormReturnType<any>;
}

export function EtiquetaResinadaFormItem({
  form,
}: EtiquetaResinadaFormItemProps) {
  useEffect(() => {
    form.setValues({
      details: {
        width: '',
        height: '',
        hasManipulation: false,
        hasResin: true,
        isTransparent: false,
        hasSample: false,
      },
    });
  }, []);

  return (
    <>
      <Group grow>
        <NumberInput
          label="Altura"
          placeholder="em cm*"
          mb={16}
          min={0}
          name="height"
          precision={2}
          step={0.5}
          {...form.getInputProps('details.height')}
        />
        <NumberInput
          label="Largura"
          placeholder="em cm*"
          mb={16}
          min={0}
          name="width"
          precision={2}
          step={0.5}
          {...form.getInputProps('details.width')}
        />
      </Group>
      <Checkbox
        label="Tem manuseio de aplicação no produto acima ?"
        color="green"
        name="hasManipulation"
        mb={16}
        {...form.getInputProps('details.hasManipulation')}
      />
      <Checkbox
        label="É adesivo vinil fundo transparente ?"
        description="selecione se desejar adesivo transparente"
        color="green"
        name="isTransparent"
        mb={16}
        {...form.getInputProps('details.isTransparent')}
      />
      <Radio.Group
        label="Tipo de produção"
        description="selecione o tipo de produção para este pedido"
        withAsterisk
        mb={16}
        {...form.getInputProps('productionType')}
      >
        <Radio value="needAssociation" label="Manuseio" />
        <Radio value="notNeedAssociation" label="Apenas folha" />
      </Radio.Group>
    </>
  );
}
