import { Button, Group, Modal, Text } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CustomLoader } from '../../components/CustomLoader';
import { Page } from '../../components/Page';
import { useRemoveReceipt, useReprintReceipt } from '../../data/hooks/receipts';
import { successNotification } from '../../providers/mantine-notifications';
import { RootState } from '../../providers/store';
import { getReceiptsPaginatedDispatcher } from '../../store/receipt';
import { realToUSCash } from '../../utils/helpers';
import { ReceiptResponse } from '../../utils/types/data/services/receipts';
import { FinancialHomeWeb } from './financial';

export type FormFilterType = {
  page: number;
  limit: number;
  os?: string | null;
  createdAt?: string | null;
  code_receipt?: string | null;
  value_receipt?: string | null;
  showCancelled?: boolean;
};

type PageModalState = 'cancel-receipt' | 'confirm-receipt' | null;

function downloadFile(response: Blob, fileName: string) {
  const url = URL.createObjectURL(new Blob([response]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  URL.revokeObjectURL(url);
}

export function ReceiptList() {
  const dispatch = useDispatch<any>();
  const currentPage = useRef(1);
  const currentPageLimit = useRef(10);
  const [selectedRecepit, setSelectedRecepit] = useState<ReceiptResponse>();
  const { items, loading, meta } = useSelector(
    (state: RootState) => state.receipt,
  );
  const [pageModalVisible, setPageModalVisible] =
    useState<PageModalState>(null);

  const { fetch: removeReceiptFetcher, loading: removeReceiptLoader } =
    useRemoveReceipt();

  const { fetch: reprintReceiptFetcher, loading: reprintReceiptLoader } =
    useReprintReceipt();

  const formFilter = useForm<FormFilterType>({
    initialValues: {
      limit: 10,
      page: 1,
      os: '',
      createdAt: undefined,
      code_receipt: '',
      value_receipt: '',
      showCancelled: false,
    },
  });

  function handleSubmit() {
    currentPage.current = 1;
    getReceiptPaginated();
  }

  function handlePaginate(page: number) {
    currentPage.current = page;
    getReceiptPaginated();
  }

  function handleChangePageLimit(limit: number) {
    currentPageLimit.current = limit;
    getReceiptPaginated();
  }

  function handleClearFilter() {
    formFilter.reset();
    getReceiptPaginated(true);
  }

  function getReceiptPaginated(isReset = false) {
    const validatedFilter: any = {};

    Object.entries(formFilter.values).forEach(([key, val]) => {
      if (val !== '' && val !== null && val !== undefined) {
        if (key === 'value_receipt') {
          validatedFilter['value_receipt'] = realToUSCash(val.toString());
        } else {
          validatedFilter[key] = val;
        }
      }
    });

    if (isReset) {
      dispatch(
        getReceiptsPaginatedDispatcher({
          page: 1,
          limit: 10,
        }),
      );
    } else {
      dispatch(
        getReceiptsPaginatedDispatcher({
          ...validatedFilter,
          page: currentPage.current,
          limit: currentPageLimit.current,
        }),
      );
    }
  }

  function handleCancelReceipt() {
    setPageModalVisible('cancel-receipt');
  }

  function abortCancelReceipt() {
    setPageModalVisible(null);
    setSelectedRecepit(undefined);
  }

  async function cancelReceipt() {
    if (selectedRecepit) {
      await removeReceiptFetcher({
        params: {
          id: selectedRecepit?.id,
        },
        onSuccess: () => {
          getReceiptPaginated();
          abortCancelReceipt();
          successNotification({
            title: 'Recibo cancelado!',
            message: 'Tudo certo.',
          });
        },
      });
    }
  }

  async function handleReprintReceipt(id: number) {
    await reprintReceiptFetcher({
      id,
      onSuccess(res) {
        downloadFile(
          res,
          `copia-recibo-${String(new Date(Date.now()).valueOf()).slice(
            7,
            11,
          )}.pdf`,
        );
        setPageModalVisible('confirm-receipt');
      },
    });
  }

  useEffect(() => {
    getReceiptPaginated();
  }, []);

  return (
    <Page>
      <CustomLoader
        loading={loading || removeReceiptLoader || reprintReceiptLoader}
      />
      <FinancialHomeWeb
        items={items}
        meta={meta}
        currentPage={currentPage}
        formFilter={formFilter}
        handleSubmit={handleSubmit}
        handleChangePageLimit={handleChangePageLimit}
        handlePaginate={handlePaginate}
        handleClear={handleClearFilter}
        handleCancelReceipt={handleCancelReceipt}
        setSelectedRecepit={setSelectedRecepit}
        handleReprintReceipt={handleReprintReceipt}
      />
      <Modal
        size={700}
        opened={pageModalVisible === 'cancel-receipt'}
        onClose={() => setPageModalVisible(null)}
        title={<Text fw="bold">Cancelar recibo</Text>}
      >
        <Text>Deseja cancelar este recibo "{selectedRecepit?.code}" ?</Text>
        <Group mt={16}>
          <Button onClick={cancelReceipt} color="green" type="submit">
            Sim
          </Button>
          <Button onClick={abortCancelReceipt} color="red" type="submit">
            Não
          </Button>
        </Group>
      </Modal>
      <Modal
        title="Exportar Recibo."
        opened={pageModalVisible === 'confirm-receipt'}
        onClose={() => setPageModalVisible(null)}
        closeOnClickOutside={false}
        withCloseButton={false}
        closeOnEscape={false}
      >
        <Group>
          <Text fw="bold">Tudo certo!</Text>
          <Text>O recibo vai aparecer em instantes no seus Downloads.</Text>
        </Group>
        <Group position="right">
          <Button onClick={() => setPageModalVisible(null)}>OK</Button>
        </Group>
      </Modal>
    </Page>
  );
}
