import {
  Box,
  CloseButton,
  MultiSelect,
  MultiSelectProps,
  MultiSelectValueProps,
  SelectItem,
  SelectItemProps,
} from '@mantine/core';
import { forwardRef } from 'react';

type CustomMultiSelectProps = MultiSelectProps & {
  data: (string | SelectItem)[];
  label: string;
  placeholder: string;
};

const ListItem = forwardRef<
  HTMLDivElement,
  SelectItemProps & { bg: string; color: string }
>(({ label, bg, color, ...others }, ref) => {
  return (
    <div ref={ref} {...others}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          backgroundColor: bg,
          color,
          fontWeight: 'bold',
          padding: 4,
          borderRadius: 4,
        }}
      >
        <div style={{ color }}>{label}</div>
      </Box>
    </div>
  );
});

function SelectValue({
  label,
  onRemove,
  bg,
  color,
  ...others
}: MultiSelectValueProps & { bg: string; color: string }) {
  return (
    <div {...others}>
      <Box
        sx={(theme) => ({
          display: 'flex',
          cursor: 'default',
          alignItems: 'center',
          backgroundColor: bg,
          color,
          border: `1px solid ${
            theme.colorScheme === 'dark'
              ? theme.colors.dark[7]
              : theme.colors.gray[4]
          }`,
          fontWeight: 'bold',
          padding: 4,
          paddingLeft: 10,
          borderRadius: 4,
        })}
      >
        <Box sx={{ lineHeight: 1, fontSize: 12, color }}>{label}</Box>
        <CloseButton
          onMouseDown={onRemove}
          variant="transparent"
          size={22}
          iconSize={14}
          tabIndex={-1}
        />
      </Box>
    </div>
  );
}

export function CustomMultiSelect({
  data,
  label,
  placeholder,
  ...props
}: CustomMultiSelectProps) {
  return (
    <MultiSelect
      data={data}
      label={label}
      placeholder={placeholder}
      itemComponent={ListItem}
      valueComponent={SelectValue}
      {...props}
    />
  );
}
